<template>
  <tr v-if="displayRow">
    <td v-if="isnewtask">
      Выберите пункт,<br />
      за который необходимо проголосовать
    </td>
    <td v-if="isnewtask">
      <div v-for="{ id, text, votes } in answers" v-bind:key="id">
        <div class="radio radio-css">
          <input type="radio" name="radio_answ" :id="'cssRadio_' + id" :value="id" v-model="answerId"
            @change="answerChaged" />
          <label v-bind:for="'cssRadio_' + id">{{ text }} [{{ votes }}]</label>
        </div>
      </div>
    </td>

    <td v-if="!isnewtask">
      Выбранный пункт
    </td>
    <td v-if="!isnewtask">
      <div class="radio radio-css">
        <input type="radio" name="radio_answ" :id="'cssRadio_1'" checked />
        <label v-bind:for="'cssRadio_1'">{{ answerTex }}</label>
      </div>
    </td>
  </tr>
</template>

<script>
import { show_error } from "../message";
import HTTP from "../http";

export default {
  name: "TaskPollRow",
  props: ["isnewtask"],
  data() {
    return { answers: [], answerId: 0, answerTex: "", displayRow: false };
  },

  mounted() {
    window.eventBus.$on("poll_selected", () => {
      let task = JSON.parse(localStorage.edit_task);
      this.pollLoad(task.link);
      this.displayRow = true;
    });

    if (!this.isnewtask) {
      let task = JSON.parse(localStorage.edit_task);
      if (task.type == "vk_poll") {
        this.answerTex = task.answer_text;
        this.displayRow = true;
      }
    }
  },
  methods: {
    answerChaged() {
      let task = JSON.parse(localStorage.edit_task);
      task.answerId = this.answerId;
      task.answerText = this.answers.filter(
        (a) => a.id == this.answerId
      )[0].text;
      localStorage.edit_task = JSON.stringify(task);
    },
    pollLoad(link) {
      console.log("pollLoad");
      HTTP()
        .post("task/getvkpoll", { link: link })
        .then((response) => {
          console.log(response);
          if (response.data.message !== undefined) {
            this.answers = [];
            console.log(response.data.message);
            show_error(this.$notify, response.data.message);
          } else {
            this.answers = response.data.answers;
            this.pollId = response.data.id;
            this.answers.unshift({
              id: 0,
              text: "Любой пункт",
              votes: 0,
            });
            console.log(response);
          }
          let task = JSON.parse(localStorage.edit_task);
          task.pollId = this.pollId;
          task.answerId = this.answerId;
          let answerText = "";
          this.answers.forEach((answer) => {
            if (answer.id == this.answerId) {
              answerText = answer.text;
            }
          });
          task.answerText = answerText;
          localStorage.edit_task = JSON.stringify(task);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>

<template>
   <InputRow
      v-if="isnewtask"
      :id="this.id"
      :fieldname="this.fieldname"
      :apiname="this.apiname"
      :help="help"
   ></InputRow>
   <EditableRow
      v-else
      :id="id"
      :record="record"
      :fieldname="fieldname"
      :apiname="apiname"
      :message="message"
      :help="help"
      :type="type"
   ></EditableRow>
</template>

<script>
export default {
   name: "TaskInputRow",
   props: [
      "isnewtask",
      "id",
      "record",
      "apiname",
      "fieldname",
      "message",
      "help",
      "type"
   ]
};
</script>

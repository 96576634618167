<template>
   <div class="progress rounded-corner progress-height " :class="calcBBG(row)">
      <div
         class="progress-bar"
         :class="calcBG(row)"
         :style="{ width: calcWidth(row) + '%' }"
      >
         {{ progressBarLabel(row) }}
      </div>
   </div>
</template>

<script>
export default {
   name: "ProgressBar",
   props: ["row"],

   methods: {
      calcBBG(row) {
         if (row.pass > 5) {
            return "bg-red-transparent-1";
         }
         return "";
      },

      calcBG(row) {
         if (row.pass > 5) {
            return "bg-danger";
         } else if (row.count > 0) {
            return "progress-bar-striped progress-bar-animated";
         } else if (row.count == 0) {
            return "bg-success";
         }
      },
      calcWidth(row) {
         return row.pass > 5
            ? 100
            : ~~((row.done / (row.count + row.done)) * 100);
      },

      progressBarLabel(row) {
         return row.pass > 5
            ? "Остановлено"
            : `${this.calcWidth(row)} % [${row.price}]`;
      }
   }
};
</script>

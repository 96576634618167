import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);
//axios.defaults.baseURL = 'http://vue.localhost/api';

export const store = new Vuex.Store({
  state: {
    token: localStorage.getItem("access_token") || null,
    baseUrl: "/api/",
    filter: "all",
    todos: [],
  },
  getters: {
    loggedIn(state) {
      return state.token !== null;
    },
    remaining(state) {
      return state.todos.filter((todo) => !todo.completed).length;
    },
    anyRemaining(state, getters) {
      return getters.remaining != 0;
    },
  },
  mutations: {
    retrieveToken(state, token) {
      state.token = token;
    },
    destroyToken(state) {
      state.token = null;
    },
    clearTodos(state) {
      state.todos = [];
    },
  },
  actions: {
    retrieveName(context) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + context.state.token;

      return new Promise((resolve, reject) => {
        axios
          .get("/user")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    register(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/register", {
            name: data.name,
            email: data.email,
            password: data.password,
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    destroyToken(context) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + context.state.token;

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .post("/api/logout")
            .then((response) => {
              localStorage.removeItem("access_token");
              context.commit("destroyToken");
              resolve(response);
              // console.log(response);
              // context.commit('addTodo', response.data)
            })
            .catch((error) => {
              localStorage.removeItem("access_token");
              context.commit("destroyToken");
              reject(error);
            });
        });
      }
    },
    async serviceLogout() {
      await this.dispatch("destroyToken");
      var re = location.href.match(/([htps:/]+)([A-z]+)\.([A-z]+\.[A-z]+)/);
      location.href = re[1] + re[2] + "." + re[3] + "/#/login";
    },
    retrieveToken(context, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/login", {
            email: credentials.username,
            password: credentials.password,
          })
          .then((response) => {
            const token = response.data.access_token;

            localStorage.setItem("access_token", token);
            context.commit("retrieveToken", token);
            resolve(response);
            console.log(response);
            // context.commit('addTodo', response.data)
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
  },
});

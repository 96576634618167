<template>
  <div>
    <div v-if="!user.nickname">
      <h4>Быстрый заказ (без регистрации):</h4>
      <span>Введите ваш ник Instagram:</span>
      <div class="form-group m-b-15">
        <input type="text" class="form-control form-control-lg" placeholder="@name" required="" autocomplete="off"
          v-model="nickname" />
      </div>

      <div class="login-buttons mt-3">
        <button @click="check_nickname" class="btn btn-primary btn-block btn-lg" :disabled="loading">
          <i v-if="loading" class="fas fa-circle-notch fa-w-16 fa-fw fa-spin"></i>
          Далее
        </button>
      </div>
    </div>
    <div v-else>
      <div>
        <div>Найдена страница:</div>
        <div class="text-center f-s-20 text-black">{{ user.first_name }}</div>
      </div>
      <div class="d-flex justify-content-center">
        <img style="border-radius:5px" :src="user.photo" alt="" />
      </div>
      <form class="form-signin pt-3">
        <div class="input-group mb-3">
          <input type="text" disabled="" class="form-control" :value="user.nickname" id="myInput" />
          <div class="input-group-append">
            <button class="btn btn-info " style="background-color: #bbb;border-color: #bbb;" type="button"
              @click="clear">
              Исправить
            </button>
          </div>
        </div>
      </form>
      <!--
      <div v-if="user.followers">подписчиков {{ user.followers }}</div>
      <div v-if="user.post.likes">Лайков {{ user.post.likes }}</div>
      <div v-if="user.post.comments">Комментариев{{ user.post.comments }}</div>
-->
      <table style="color:black">
        <tr>
          <td style="width:80%">Подписчики:</td>
          <td class="text-center">{{ user.followers }}</td>
          <td>
            <button class="btn btn-primary  btn-sm ml-4" @click="fast_register('in_user')">
              Заказать
            </button>
          </td>
        </tr>
        <tr>
          <td style="width:80%">Лайки:(последний пост)</td>
          <td class="text-center">{{ user.post.likes }}</td>
          <td>
            <button class="btn btn-primary  btn-sm ml-4" @click="fast_register('in_like')">
              Заказать
            </button>
          </td>
        </tr>

        <tr>
          <td style="width:80%">Комметнарии:(последний пост)</td>
          <td class="text-center">
            {{ user.post.comments }}
          </td>
          <td>
            <button class="btn btn-primary  btn-sm ml-4" @click="fast_register('in_comnt')">
              Заказать
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { show_error } from "../message";
import HTTP from "../http";

export default {
  name: "FastOrder",
  data() {
    return {
      nickname: "",
      loading: false,
      username: "",
      password: "",
      user: {
        photo: "",
        first_name: "",
        nickname: "",
        followers: 110,
        post: {
          shortcode: "",
          likes: 0,
          comments: 0,
        },
      },
    };
  },

  mounted() { },
  methods: {
    clear() {
      console.log("clear");
      this.user.nickname = "";
    },
    check_nickname() {
      this.$insProgress.start();
      this.loading = true;
      HTTP()
        .post("/start/check_nickname", {
          nickname: this.nickname,
        })
        .then((resp) => {
          console.log(resp);
          if (resp.data.message) {
            console.log(resp.data.message);
            show_error(this.$notify, resp.data.message);
          } else {
            this.user = resp.data.response;
            localStorage.setItem("username", this.user.email);
            console.log(this.user);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.message);
          console.log(error.response.data);
          show_error(this.$notify, "Страница не найдена, попробуйте еще раз");
        })
        .finally(() => {
          this.loading = false;
          this.$insProgress.finish();
        });
    },

    fast_register(type) {
      this.$insProgress.start();
      this.loading = true;
      /*this.username =
        Math.random()
          .toString(36)
          .replace(/[^A-z0-9]+/g, "")
          .substr(0, 6) + "@turboliker.com";*/
      this.username = localStorage.username;
      this.password = Math.random()
        .toString(36)
        .replace(/[^A-z0-9]+/g, "")
        .substr(0, 10);
      localStorage.setItem("username", this.username);
      localStorage.setItem("password", this.password);
      console.log("this.user", this.user);
      HTTP()
        .post("/auth/fast_register", {
          type,
          email: this.username,
          password: this.password,
          ref: localStorage.getItem("ref"),
          http_referer: localStorage.getItem("http_referer"),
          shortcode: this.user.post.shortcode,
          nickname: this.user.nickname,
        })
        .then((resp) => {
          console.log(resp);
          if (resp.data.error) {
            show_error(this.$notify, "Страница не найдена, попробуйте еще раз");
          } else {
            localStorage.setItem("access_token", resp.data.access_token);
            localStorage.setItem("userPhoto", resp.data.userPhoto);
            localStorage.setItem("userName", resp.data.userName);
            localStorage.setItem("userGold", resp.data.userGold);
            localStorage.setItem("curNet", resp.data.curNet);
            //localStorage.setItem("message", resp.data.message);
            localStorage.setItem("is_fast_order", "true");
            location.href = "/";
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.message);
          console.log(error.response.data);
          show_error(this.$notify, "Страница не найдена, попробуйте еще раз");
        })
        .finally(() => {
          this.loading = false;
          this.$insProgress.finish();
        });
    },
  },
};
</script>

<template>
  <div>
    <!-- end page-header -->
    <panel title="Заработать лайки">
      <div class="tasks">
        <div class="text-center p-10">
          <!--<a href="javascript:;" class="btn btn-social-icon btn-vk  m-r-1 m-b-5 " :class="{ active: curNet == 'vk' }"
            style="width:100px" @click="setNet('vk')"><span class="fab fa-vk"></span></a>

          <a href="javascript:;" class="btn btn-social-icon btn-instagram  m-r-1 m-b-5"
            style="background-color: #E1306C;width:100px" :class="{ active: curNet == 'in' }"
            @click="setNet('in')"><span class="fab fa-instagram"></span></a>
          <a href="javascript:;" class="btn btn-social-icon btn-pinterest  m-r-2 m-b-5"
            :class="{ active: curNet == 'yt' }" style="width:100px" @click="setNet('yt')"><span
              class="fab fa-youtube"></span></a> -->
        </div>

        <div class="text-center" data-step="1" data-intro="Выберите тип заданий, которые Вы будете выполнять">
          <div class="btn-group  m-b-5">
            <button @click="setType('vk_like')" :class="{ active: curType == 'vk_like' }" class="btn btn-white">
              Лайки
            </button>
            <button @click="setType('vk_repost')" :class="{ active: curType == 'vk_repost' }" class="btn btn-white">
              Репосты
            </button>
            <button @click="setType('vk_frnd')" :class="{ active: curType == 'vk_user' }" class="btn btn-white">
              Друзья
            </button>
            <button @click="setType('vk_group')" :class="{ active: curType == 'vk_group' }" class="btn btn-white">
              Группы
            </button>
            <button @click="setType('vk_token')" :class="{ active: curType == 'vk_token' }" class="btn btn-white">
              Токены
            </button>
            <button @click="setType('vk_cmnt')" :class="{ active: curType == 'vk_cmnt' }" class="btn btn-white">
              Комментарии
            </button>
            <!--<button @click="setType('vk_poll')" :class="{ active: curType == 'vk_poll' }" class="btn btn-white">
              Опросы
            </button>-->
          </div>
        </div>

        <div v-if="curNet == 'in'" class="text-center" data-step="1"
          data-intro="Выберите тип задаий, которые Вы будете выполнять">
          <div class="btn-group  m-b-5">
            <button @click="setType('in_like')" :class="{ active: curType == 'in_like' }" class="btn btn-white">
              Лайки
            </button>
            <button @click="setType('in_user')" :class="{ active: curType == 'in_user' }" class="btn btn-white">
              Подписчики
            </button>
            <button @click="setType('in_comnt')" :class="{ active: curType == 'in_comnt' }" class="btn btn-white">
              Комментарии
            </button>
          </div>
        </div>

        <div v-if="curNet == 'yt'" class="text-center" data-step="1"
          data-intro="Выберите тип задаий, которые Вы будете выполнять">
          <div class="btn-group  m-b-5">
            <button @click="setType('yt_user')" :class="{ active: curType == 'yt_user' }" class="btn btn-white">
              Подписчики
            </button>
            <button @click="setType('yt_like')" :class="{ active: curType == 'yt_like' }" class="btn btn-white">
              Лайки
            </button>
            <button @click="setType('yt_dlke')" :class="{ active: curType == 'yt_dlke' }" class="btn btn-white">
              Дизлайки
            </button>
            <!--<button @click="setType('yt_favr')" :class="{ active : curType == 'yt_favr' }"
                                class="btn btn-white">
                            Избранное
                        </button>-->
            <button @click="setType('yt_cmnt')" :class="{ active: curType == 'yt_cmnt' }" class="btn btn-white">
              Комментарии
            </button>
          </div>
        </div>
        <div v-show="curType == 'vk_cmnt'" class="alert alert-info fade show h-100 mb-0">
          Чтобы выполнить задание:
          <ul>
            <li>Нажмите на иконку задания</li>
            <li>В открывшемся окне напишите любой осмысленный комментарий от 10 символов</li>
            <li>Закройте окно, лайки за выполнение будут зачислены на ваш баланс</li>
          </ul>
        </div>
        <div v-show="curType == 'vk_token'" class="alert alert-info fade show h-100 mb-0">
          Токены нужны для проверки заданий.<br>
          Чтобы выполнить задание:
          <ul>
            <li>Нажмите на иконку задания</li>
            <li>В открывшемся окне нажмите разрешить</li>
            <li>Далее скопируйте строку из адресной строки браузера и вставьте ее в текстовое поле задания</li>
            <li>Нажмите кнопку проверки (синяя)</li>
          </ul>
        </div>

        <div data-step="2"
          data-intro="Нажмите на иконку задания. В открывшемся окне нажмите лайк (репост или подписаться в зависимости от выбранного типа задания) и закройте окно. После проверки лайки будут переведены на ваш баланс">
          <div v-if="loading" class="text-center bg-silver m-20 p-20">
            <h4>Загружаем....</h4>
          </div>
          <div v-else-if="message" class="text-center bg-silver m-20 p-20">
            <h4>{{ message }}</h4>
          </div>
          <div v-else-if="tasks && tasks.length" class="text-center">
            <div v-if="isMobile()">
              <StepTaskIcon :job="tasks[0]" :index="0" :curNet="curNet" :curType="curType" :isMobile="isMobile()">
              </StepTaskIcon>
            </div>
            <div v-if="!isMobile()" class="" style="display: flex;flex-wrap: wrap;justify-content: center  ">
              <div v-for="(task, index) in tasks" :key="index">
                <StepTaskIcon :job="task" :index="index" :curNet="curNet" :curType="curType" :isMoblie="isMobile()">
                </StepTaskIcon>
              </div>
            </div>
          </div>
          <div v-else class="text-center  m-t-30 m-b-40">
            <div v-if="status == 'need_auth'">
              <div class="text-center">
                Для выполнения заданий Вам нужно перейти в настройки и привязать аккаунт
              </div>
            </div>
            <div v-else>
              <div class="text-center">
                Задания закончились. Попробуйте зайти позже
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!isMobile()" class="text-center m-t-10">
        <a @click.prevent="introJSStart" class="btn  btn-default m-r-5 m-t-5 mobbtn" data-step="4"
          data-intro="Чтобы посмотреть это руководство еще раз  на конпку 'Помощь' ">
          <i class="fas fa-info-circle m-r-3"></i>
          <span>Помощь</span>
        </a>

        <a href="#/home" class="btn  btn-default m-r-5 m-t-5 mobbtn">
          <i class="fas fa-plus m-r-3"></i>
          <span>Добавить задание</span>
        </a>

        <a @click.prevent="fetchData" href="javascript:;" class="btn btn-default m-t-5 m-r-20 mobbtn" data-step="3"
          data-intro="Если задания закончились нажмите кнопку 'Обновить' ">
          <i class="fas fa-sync-alt m-r-3"></i>
          Обновить
        </a>
      </div>
    </panel>
  </div>
</template>
<script>
import HTTP from "../http";
import { show_error } from "../message";

export default {
  data() {
    return {
      loading: false,
      status: "",
      tasks: [],
      curType: "vk_like",
      curNet: "vk",
      message: "",
      insta_follow: false,
    };
  },
  created() {
    if (localStorage.curNet) {
      this.curNet = localStorage.curNet;
    }
    this.setNet(this.curNet);
    this.insta_follow = process.env.MIX_INSTAGRAM_FOLLOW == "true";
    //console.log(this.insta_follow);
  },
  mounted() {
    if (!localStorage.introJS_Step) {
      this.introJSStart();
    }
    window.eventBus.$on("taskUpdate", () => {
      this.fetchData();
    });
    window.eventBus.$on("jobUpdate", () => {
      this.fetchData();
    });


  },

  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent //|| window.innerWidth < 600
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    introJSStart() {
      const introJS = require("intro.js");
      //onexit oncomplete
      introJS
        .introJs()
        .onexit(function () {
          localStorage.setItem("introJS_Step", true);
        })
        .start();
    },

    setNet(net) {
      this.curNet = net;
      localStorage.setItem("curNet", net);
      this.curType = localStorage[this.curNet + "_curType"];
      if (!this.curType) {
        if (this.curNet == "vk") {
          this.curType = "vk_like";
        } else if (this.curNet == "in") {
          this.curType = "in_like";
        } else if (this.curNet == "yt") {
          this.curType = "yt_like";
        }
      }
      this.fetchData();
    },
    setType(type) {
      this.curType = type;
      localStorage.setItem(this.curNet + "_curType", type);
      this.fetchData();
    },
    fetchData() {
      //this.loading = true;
      //this.tasks = [];
      this.$insProgress.start();

      HTTP()
        .get("job?type=" + this.curType)
        .then((response) => {
          console.log('response', response)
          this.loading = false;
          response.data?.jobs?.forEach(function (element) {
            element.status = 0;
          });

          this.tasks = response.data?.jobs;
          this.status = response.data?.status;
          this.message = response.data?.message;
          window.eventBus.$emit("goldUpdate", response.data?.gold);
          this.$insProgress.finish();
        })
        .catch((error) => {
          this.loading = false;
          show_error(
            this.$notify, error.response.data
          );
          this.$insProgress.finish();
        });
    },

    parseError(error) {
      if (error == "Unauthenticated.") {
        //this.$store.dispatch("serviceLogout");
        return "Произошла ошибка при попытке авторизации. Попробуйте зайти на сайт еще раз";
      }
      return error;
    },

    show(group, type, title, text) {
      text = this.parseError(text);
      this.$notify({
        group,
        title,
        text,
        type,
      });
    },
  },
};
</script>

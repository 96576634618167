<template>
  <!--
  <ul class="nav">
    <li class="nav-profile">
      <div class="cover with-shadow"></div>
      <div style="display:flex">
        <div
          class="image image-icon bg-black text-grey-darker mr-2"
          id="user_photo"
        >
          <img
            onerror="this.onerror=null;this.src='/images/vk100.png';"
            :src="userPhoto"
          />
        </div>
        <div
          class="info"
          id="user_name"
          style="width: 100px"
          v-html="userName"
        ></div>
      </div>
      <div class="text-center f-s-20" style="z-index:100">
        <b><b id="gold" v-html="userGold"></b> <i class="far fa-heart"></i> </b>
        <a
          href="#/shop"
          class="btn btn-primary  mobbtn m-l-10 p-t-3 p-b-3 p-l-10 p-r-10"
          style="color: white; text-decoration: none;"
          ><b>Купить</b></a
        >
      </div>
    </li>
  </ul>
-->
  <ul class="nav">
    <li class="nav-profile">
      <div class="cover with-shadow"></div>
      <div class="d-flex flex-row ">
        <div class="image image-icon bg-black text-grey-darker">
          <img onerror="this.onerror=null;this.src='/images/vk100.png';" :src="userPhoto" />
        </div>
        <div class="info p-l-10" id="user_name" style="width: 100px" v-html="userName"></div>
      </div>

      <div class="info m-t-5 d-flex flex-row f-s-20 ">
        <div class=" d-flex flex-row">
          <b id="gold" v-html="userGold"></b>
          <i class="far fa-heart p-l-5 p-t-5"></i>
        </div>
        <div v-show="shop">
          <a href="#/shop" class="btn btn-primary  mobbtn m-l-10 p-t-3 p-b-3 p-l-10 p-r-10"
            style="color: white; text-decoration: none;"><b>Купить</b></a>
        </div>
      </div>
    </li>
  </ul>

  <!-- bottom right animation example -->
</template>

<script>
export default {
  name: "SidebarNavProfile",
  data() {
    return {
      stat: "",
      userPhoto: "",
      userName: "",
      userGold: 0,
      shop: false
    };
  },
  created() {
    if (localStorage.photo) {
      this.userPhoto = localStorage.photo;
    }
    if (localStorage.balance) {
      this.userGold = localStorage.balance;
    }
    if (localStorage.username) {
      //this.userName = localStorage.username;
    }
    window.eventBus.$on("goldUpdate", (gold) => {
      this.userGold = gold ? gold : this.userGold;
      localStorage.balance = gold ? gold : this.userGold;
    });
    console.log('VUE_APP_SHOP_ENABLED', process.env.VUE_APP_SHOP_ENABLED)
    console.log('VUE_APP_SHOP_ENABLED', typeof process.env.VUE_APP_SHOP_ENABLED)
    this.shop = process.env.VUE_APP_SHOP_ENABLED == 'true'
  },

  methods: {
    expand: function () {
      this.stat = this.stat == "expand" ? "collapse" : "expand";
    },
  },
};
</script>

<template>
  <tr>
    <td @click="showmessage(help)">
      <span v-if="apiname == 'price'">
        {{ fieldname }}
      </span>
      <span v-else>
        {{ fieldname }}
      </span>

      <b class="pull-right pt-1">
        <div class="btn btn-xs btn-white">
          <i class="fas fa-info-circle"></i>
        </div>
      </b>
    </td>
    <td>
      <div>
        <input
          class="form-control"
          type="text"
          v-model="record"
          @input="changeRecord"
        />
      </div>
    </td>
  </tr>
</template>

<script>
//import HTTP from "../http";
import { show_message } from "../message";

export default {
  name: "InputRow",
  props: ["id", "apiname", "fieldname", "help"],
  data() {
    return { record: "" };
  },

  mounted() {
    if (this.apiname == "price") {
      /*
      window.eventBus.$on("task_changed", (type) => {
        HTTP()
          .get("task/price/" + type)
          .then((response) => {
            this.record = Math.floor(response.data[0].price);
            this.changeRecord();
          })
          .catch((error) => {
            this.message = error.response.data.message;
            show_error(
              this.$notify,
              error.response.data.message || error.message
            );
          });
      });*/
    }
  },

  methods: {
    showmessage(message) {
      show_message(this.$notify, message);
    },
    changeRecord() {
      if (localStorage.edit_task != null) {
        let task = JSON.parse(localStorage.edit_task);
        task[this.apiname] = this.record;
        localStorage.edit_task = JSON.stringify(task);
      } else {
        let task = { [this.apiname]: this.record };
        localStorage.edit_task = JSON.stringify(task);
      }
      window.eventBus.$emit("record_changed");
    },
  },
};
</script>

<template>
  <div>
    <div v-if="id_ok">
      <h3>Создать купон:</h3>
      <p>Здесь вы можете создать купон для задания на сайте fastsmm.ru.<br>
        Купон можно только создать, его нельзя передать другим пользователям.
      </p>
      <div class="text-center" v-if="!coupon">
        <div class="btn btn-primary" @click="createCoupon()">Создать купон</div>
      </div>
      <div v-else class="pt-4">
        <h4>Купон создан:</h4>
        <b>{{ coupon.coupon }}</b><br>
        <small>Пожалуйста отправьте его в отчете</small>
      </div>
    </div>
    <div v-else class="text-center">
      <div class="alert alert-primary fade show">
        Для создания купона пожалуйста зарегистрируйте новый аккаунт.
      </div>
    </div>
  </div>
</template>
<script>
import HTTP from "../http";
import { show_error } from "../message";

export default {
  data() {
    return {
      error: null,
      data: null,
      loading: false,
      id_ok: false,
      coupon: null
    };
  },

  created() {
    this.fetchData();
  },


  methods: {
    createCoupon() {
      this.error = null;
      this.tasks = [];
      this.loading = true;
      HTTP()
        .post("/coupon")
        .then((response) => {
          this.loading = false;
          console.log('response', response.data)
          this.coupon = response.data
        })
        .catch((error) => {
          this.loading = false;

          show_error(
            this.$notify, error.response.data
          );
        });
    },
    fetchData() {
      this.error = null;
      this.tasks = [];
      this.loading = true;
      HTTP()
        .get("/coupon")
        .then((response) => {
          this.loading = false;
          this.id_ok = response.data.id_ok
        })
        .catch((error) => {
          this.loading = false;

          show_error(
            this.$notify, error.response.data
          );
        });
    },
  },
};
</script>

<style>
.progress-height {
  margin-top: 2px;
  height: 18px !important;
}

.fa-size {
  font-size: 1.8em;
}

.selectedRow {
  background-color: red;
}

@media (max-width: 767px) {
  .td-visible {
    display: none;
  }

  .td-width {
    min-width: 80% !important;
    max-width: 80% !important;
    width: 80% !important;
  }

  .mobbtn {
    width: 100%;
  }
}
</style>

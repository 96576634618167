<template>
  <!-- begin login -->
  <div class="login login-with-news-feed" style="height:100%">
    <!-- begin news-feed -->
    <div class="news-feed">
      <div class="news-image" style="background-image: url(/images/bg.jpg)"></div>
      <div class="news-caption">
        <h4 class="caption-title">{{ siteName }}</h4>
        <p>
          Накрутка лайков, друзей, подписчиков
        </p>
      </div>
    </div>
    <!-- end news-feed -->
    <!-- begin right-content -->
    <div class="right-content d-flex flex-column justify-content-center">
      <!-- begin login-header -->
      <div class="text-center m-t-20"></div>
      <!-- end login-header -->

      <div class="login-content">
        <h1 class="text-center">{{ siteName }}</h1>
        <p>Введите ваш Email:</p>
        <div class="hidt">
          <div class="mt-3">
            <div class="form-group m-b-15">
              <input type="" class="form-control form-control-lg" placeholder="Email" required="" autocomplete="off"
                v-model="email" />
            </div>
          </div>

          <div class="login-buttons mt-3">
            <button @click="reset" class="btn btn-primary btn-block btn-lg" :disabled="loading">
              <i v-if="loading" class="fas fa-circle-notch fa-w-16 fa-fw fa-spin"></i>
              Сбросить пароль
            </button>
          </div>
          <div class="m-t-20 m-b-40 p-b-40 text-inverse">
            Есть аккаунт? <a href="/#/login">Войти</a><br>
            Нет аккаунта? <a href="/#/register">Регистрация</a>
          </div>
        </div>
      </div>
      <div class="text-center">
        Не получается войти?<br />
        Пишите:
        <a href="mailto:support.turboliker@ya.ru">support.turboliker@ya.ru</a>
      </div>
    </div>
    <!-- end right-container -->
  </div>
  <!-- end login -->
</template>

<script>
import PageOptions from "../config/PageOptions.vue";
import HTTP from "../http";
import { show_error, show_message } from "../message";

export default {
  data() {
    return {
      email: "",
      serverError: "",
      successMessage: this.dataSuccessMessage,
      loading: false,
      ref: "",
      http_referer: "",
      vk_link: "",
      in_link: "",
      siteName: ''
    };
  },

  created() {
    this.siteName = process.env.VUE_APP_SITE_NAME
    //document.body.className = "bg-white"; 
    console.log("page loaded ");
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {
    checkForm: function () { },

    reset() {
      this.$insProgress.start();
      this.loading = true;
      HTTP()
        .post("/forgot-password", {
          email: this.email,
        })
        .then((resp) => {
          console.log('resp', resp)
          show_message(
            this.$notify,
            resp.data
          );
        })
        .catch((error) => {
          console.log('error', error);
          console.log('error.message', error.message);
          console.log('error.response.data', error.response.data);
          show_error(
            this.$notify, error.response.data
          );
          this.loading = false;
          this.$insProgress.finish();
        })
        .finally(() => {
          this.loading = false;
        })
    },
  },
};
</script>


<style>
.hidt {
  min-width: 300px
}
</style>
function parseError(error) {
   console.log('error',error)

   if(typeof error?.errors != 'undefined' )
   {
      if(error.errors[0].message == "E_UNAUTHORIZED_ACCESS: Unauthorized access")
      {
         window.location.replace('/#/logout')
         return "Произошла ошибка при попытке авторизации. Попробуйте выйти и зайти еще раз";
      }
      return error.errors[0].message
   }
   if(error?.message )
   {
      return error.message
   }
   return error;
}

function show_message(notify, text) {
   text = parseError(text);
   notify({
      group: "bottom-right",
      title: "Сообщение",
      type: "info",
      text: text
   });
}

function show_error(notify, text) {
   text = parseError(text);
   notify({
      group: "bottom-right",
      title: "Ошибка",
      type: "warn",
      text: text
   });
}

export { show_message, show_error };

<template>
  <!-- begin login -->
  <div class="login login-with-news-feed" style="height:100%">
    <!-- begin news-feed -->
    <div class="news-feed">
      <div class="news-image" style="background-image: url(/images/bg.jpg)"></div>
      <div class="news-caption">
        <h4 class="caption-title">{{ siteName }}</h4>
        <p>
          Накрутка лайков, друзей, подписчиков
        </p>
      </div>
    </div>
    <!-- end news-feed -->
    <!-- begin right-content -->
    <div class="right-content d-flex flex-column justify-content-center">
      <!-- begin login-header -->
      <div class="text-center m-t-20"></div>
      <!-- end login-header -->

      <div class="login-content">
        <h1 class="text-center">{{ siteName }}</h1>
        <p>Регистрация:</p>

        <div style="min-width:350">
          <div class="login-buttons">
            <a :href="vk_link" class="mt-3 btn btn-social btn-vk btn-block btn-lg">
              <span class="fab fa-vk"></span>
              <span class="ml-3">Войти через ВКонтакте</span>
            </a>
          </div>

          <div class="mt-3">
            <div class="form-group m-b-15">
              <input type="text" class="form-control form-control-lg" placeholder="Email" required="" autocomplete="off"
                v-model="username" />
            </div>
            <div class="form-group m-b-15">
              <input type="password" class="form-control form-control-lg" placeholder="Пароль" required=""
                autocomplete="off" v-model="password" />
            </div>
          </div>

          <div class="login-buttons mt-3">
            <!--<vue-recaptcha
              :sitekey="sitekey"
              ref="recaptcha"
              size="invisible"
              @verify="register"
              @expired="onCaptchaExpired"
              :disabled="loading"
            >
              <button
                class="btn btn-primary btn-block btn-lg"
                :disabled="loading"
              >
                <i
                  v-if="loading"
                  class="fas fa-circle-notch fa-w-16 fa-fw fa-spin"
                ></i>
                Зарегистрироваться
              </button>
            </vue-recaptcha>-->


            <button @click="register" class="btn btn-primary btn-block btn-lg" :disabled="loading">
              <i v-if="loading" class="fas fa-circle-notch fa-w-16 fa-fw fa-spin"></i>
              Зарегистрироваться
            </button>
          </div>
          <div class="m-t-20 m-b-40 p-b-40 text-inverse">
            Есть аккаунт? <a href="/#/login">Войти</a><br>
            Забыли пароль? <a href="/#/forgot-password">Восстановить пароль</a>
          </div>
        </div>
      </div>
      <div class="text-center">
        Не получается войти?<br />
        Пишите:
        <a href="mailto:support.turboliker@ya.ru">support.turboliker@ya.ru</a>
      </div>
    </div>
    <!-- end right-container -->
  </div>
  <!-- end login -->
</template>

<script>
import PageOptions from "../config/PageOptions.vue";
import HTTP from "../http";
import { show_error } from "../message";


export default {
  data() {
    return {
      username: "",
      password: "",
      serverError: "",
      successMessage: this.dataSuccessMessage,
      loading: false,
      vk_link: "",
      siteName: ''
    };
  },

  created() {
    this.siteName = process.env.VUE_APP_SITE_NAME

    if (localStorage.getItem("username")) {
      this.username = localStorage.getItem("username");
    }

    if (localStorage.getItem("password")) {
      this.password = localStorage.getItem("password");
    }

    if (this.$route.query.ref) {
      localStorage.setItem("ref", this.$route.query.ref);
    }

    if (this.$route.query.referer) {
      localStorage.setItem("referer", this.$route.query.referer);
    }

    if (this.$route.query.token) {
      this.loginByToken(this.$route.query.token);
    }

    this.vk_link = process.env.VUE_APP_LOGINEZ + encodeURIComponent(process.env.VUE_APP_LOGIN_URL + 'login');

    console.log("Register page loaded ");
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {


    checkForm: function () {
      /*
      e.preventDefault();
      console.log(this.email + '  ' + this.password)
      this.$router.push({ path: '/home' })
      */
    },

    register() {
      this.$insProgress.start();
      this.loading = true;
      localStorage.setItem("username", this.username);
      localStorage.setItem("password", this.password);
      HTTP()
        .post("/auth/register", {
          email: this.username,
          password: this.password,
          ref: localStorage.getItem("ref"),
          referer: localStorage.getItem("referer"),
        })
        .then((resp) => {
          console.log("resp", resp);
          localStorage.setItem("access_token", resp.data.token.token);
          localStorage.setItem("photo", resp.data.user.photo);
          localStorage.setItem("balance", resp.data.user.balance);
          localStorage.setItem("message", resp.data.message);
          localStorage.setItem("id", resp.data.user.id);
          location.href = "/";
        })
        .catch((error) => {
          console.log('error', error);
          show_error(
            this.$notify, error.response.data
          );
        })
        .finally(() => {
          this.loading = false;
          this.$insProgress.finish();
        });
    },
  },
};
</script>

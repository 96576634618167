import PanelComponent from "./Panel.vue";
import EditableRowComponent from "./EditableRow.vue";
import InputRowComponent from "./InputRow.vue";
import TaskInputRowComponent from "./TaskInputRow.vue";
import TaskLinkRowComponent from "./TaskLinkRow.vue";
import TaskTypeRowComponent from "./TaskTypeRow.vue";
import TaskIconComponent from "./TaskIcon.vue";
import ProgressBarComponent from "./ProgressBar.vue";
import TaskPollRowComponent from "./TaskPollRow.vue";
import ProfileEditableRowComponent from "./ProfileEditableRow.vue";
import SocAddButtonComponent from "./SocAddButton.vue";

import StepTaskIconComponent from "./StepTaskIcon.vue";
import FastOrderComponent from "./FastOrder.vue";

const Plugins = {
  install(Vue) {
    Vue.component(PanelComponent.name, PanelComponent);
    Vue.component(EditableRowComponent.name, EditableRowComponent);
    Vue.component(InputRowComponent.name, InputRowComponent);
    Vue.component(TaskInputRowComponent.name, TaskInputRowComponent);
    Vue.component(TaskLinkRowComponent.name, TaskLinkRowComponent);
    Vue.component(TaskTypeRowComponent.name, TaskTypeRowComponent);
    Vue.component(TaskIconComponent.name, TaskIconComponent);
    Vue.component(ProgressBarComponent.name, ProgressBarComponent);
    Vue.component(TaskPollRowComponent.name, TaskPollRowComponent);
    Vue.component(StepTaskIconComponent.name, StepTaskIconComponent);
    Vue.component(SocAddButtonComponent.name, SocAddButtonComponent);
    Vue.component(FastOrderComponent.name, FastOrderComponent);
    Vue.component(
      ProfileEditableRowComponent.name,
      ProfileEditableRowComponent
    );
  },
};

export default Plugins;

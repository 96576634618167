<template>
  <div>
    <div style="display:inline-block;border-radius:5px" class=" m-5 bg-silver-lighter p-10">
      <div>
        <a style="text-decoration: none" href="javascript://" @click="openWin(job.id, index, job.link)">
          <div style="display:inline-block;position:relative;">
            <p style="display:inline-block;
                     position:absolute;
                     border-top-left-radius:5px;
                     border-bottom-right-radius:5px;
                     font-weight: 600;
                     backgroundColor:#222" class="f-s-16 text-center text-white">
              &nbsp; <b>+</b>{{ job.price }}
              <i class="fa fa-heart-o m-l-2"></i>
              &nbsp;
            </p>
            <img style="border-radius:5px" @error="imageLoadOnError" :src="job.image" :width="isMobile ? 250 : 100"
              :height="isMobile ? 250 : 100" />
          </div>
        </a>
      </div>
      <div>
        <input v-show="curType == 'vk_token'" type="text" class="form-control pt-2 mt-2" style="width:100%"
          placeholder="https://oauth.vk.com/blank.html" v-model="token" />
      </div>
      <div v-if="job.answer_text" class="m-10" style="color:black">
        <b>Проголосовать за:</b><br />
        {{ job.answer_text }}
      </div>
      <div>
        <div v-if="isMobile" class="m-t-5"></div>
        <div v-if="job.status == 0">
          <div v-if="!isMobile" class="btn-group m-t-5">
            <button class="btn btn-info" @click="checkjob(job.id, index)">
              <i class="fa fa-check-square"></i>
            </button>
            <button class="btn btn-danger" @click="passjob(job.id)">
              <i class="fa fa-trash"></i>
            </button>
          </div>
          <div v-else class="m-t-5">
            <button class="btn btn-white mobilebtn m-t-5" @click="openWin(job.id, index, job.link)" disabled>
              Нажмите на картинку, чтобы выполнить
            </button>
          </div>
        </div>

        <div v-if="job.status == 1" class="btn btn-white m-t-5"
          v-bind:class="{ mobilebtn: isMobile, descbtn: !isMobile }">
          <i class="fas fa-sync-alt"></i>
        </div>
        <div v-if="job.status == 2" class="btn btn-primary m-t-5"
          v-bind:class="{ mobilebtn: isMobile, descbtn: !isMobile }">
          <i class="fa fa-plus"></i><b class="f-s-14 m-l-2">{{ job.price }}</b><i class="m-l-2 fas fa-heart"></i>
        </div>
        <div v-if="job.status == 3" class="btn btn-grey m-t-5"
          v-bind:class="{ mobilebtn: isMobile, descbtn: !isMobile }" v-b-tooltip.hover title="Закончились лайки">
          <i class="fa fa-times"></i>
        </div>
        <div v-if="job.status == 4" class="btn btn-danger m-t-5"
          v-bind:class="{ mobilebtn: isMobile, descbtn: !isMobile }" v-b-tooltip.hover title="Ошибка">
          <i class="fas fa-exclamation-triangle"></i>
        </div>
        <div v-if="job.status == 5" class="btn btn-danger m-t-5"
          v-bind:class="{ mobilebtn: isMobile, descbtn: !isMobile }" v-b-tooltip.hover title="Задание не выполнено">
          <i class="fas fa-exclamation-triangle"></i>
        </div>
        <div v-if="job.status == -1" class="btn btn-danger m-t-5"
          v-bind:class="{ mobilebtn: isMobile, descbtn: !isMobile }">
          <i class="fa fa-trash"></i>
        </div>
        <div v-if="job.status == 6">
          <button class="btn btn-white mobilebtn m-t-10 " @click="checkjob(job.id, index)"
            data-intro="Нажмите кноку 'проверить' если после выполнения задания лайки не засчитались  ">
            <i class="fa fa-check-square"></i> Проверить задание
          </button>

          <button class="btn btn-white mobilebtn m-t-10"
            data-intro="Нажмите кноку 'пропустить' чтобы перейти к следующему заданию" @click="passjob(job.id);">
            <i class="fa fa-trash"></i> Пропустить это задание
          </button>

        </div>

        <div v-if="isMobile">
          <button class="btn btn-white mobilebtn m-t-10 " @click="checkjob(job.id, index)"
            data-intro="Нажмите кноку 'проверить' если после выполнения задания лайки не засчитались  ">
            <i class="fa fa-check-square"></i> Проверить задание
          </button>

          <button class="btn btn-white mobilebtn m-t-10"
            data-intro="Нажмите кноку 'пропустить' чтобы перейти к следующему заданию" @click="passjob(job.id);">
            <i class="fa fa-trash"></i> Пропустить это задание
          </button>

          <a @click.prevent="introJSStart" class="btn  btn-default m-r-5 m-t-5 mobbtn" data-step="4"
            data-intro="Чтобы посмотреть это руководство еще раз  на конпку 'Помощь' ">
            <i class="fas fa-info-circle m-r-3"></i>
            <span>Помощь</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HTTP from "../http";
import { show_error } from "../message";

export default {
  name: "StepTaskIcon",
  props: ["job", "index", "curNet", "curType", "isMobile"],
  data() {
    return { message: "", cooked: false, token: '' };
  },

  mounted() {
    if (this.isMobile) {
      window.onfocus = () => {
        if (this.cooked && this.curType != 'vk_token') {
          this.checkjob(this.job.id, this.index);
        }
      };
    }
    //console.log('job', this.job)
    //console.log('index', this.index)
  },
  methods: {
    introJSStart() {
      const introJS = require("intro.js");
      //onexit oncomplete
      introJS
        .introJs()
        .onexit(function () {
          localStorage.setItem("introJS_Step", true);
        })
        .start();
    },
    jobUpdate() {
      this.token = ''
      window.eventBus.$emit("jobUpdate");
    },
    passjob(id) {
      this.cooked = false;
      this.job.loading = true;
      this.job.status = 1;
      HTTP()
        .delete("job/" + id)
        .then((response) => {
          this.job.status = -1;
          //eventBus.$emit('goldUpdate', response.data.gold);
          if (
            typeof response.data.error !== "undefined" &&
            typeof response.data.error.message !== "undefined"
          ) {
            show_error(
              this.$notify,
              response.data.error.message || response.data.error
            );
          }
        })
        .catch((error) => {
          show_error(
            this.$notify,
            error.response.data.message || error.message
          );
        }).finally(() => {
          if (this.isMobile) {
            setTimeout(() => {
              this.token = ''
              window.eventBus.$emit("jobUpdate");
            }, 1 * 1000);
          }
        })
    },

    openJob(id) {
      HTTP()
        .put("/job/" + id, { mode: 'open' })
        .then((response) => {
          this.loading = false;
          console.log(response.data.debug);
        })
        .catch((error) => {
          this.loading = false;

          show_error(
            this.$notify,
            error.response.data.message || error.message
          );
        });
    },

    checkjob(id) {
      this.cooked = false;
      this.checkResult(id);
    },

    checkResult(id) {

      console.log('checkResult')

      this.job.loading = true;
      this.job.status = 1;



      if (this.curType == 'vk_token' && !this.token) {
        this.job.loading = false;
        this.job.status = 0;
        alert('Неправильная  строка. Строка должна  быть вида: https://oauth.vk.com/blank.html#access_token=XXXXXXXX&expires_in=0')
        return;
      }

      if (this.curType == 'vk_token') {
        let token_string = this.token
        let regex = /access_token=([\S]+)&expires_in/g
        let match = regex.exec(token_string)
        console.log('match', match)

        if (!match || !match[1]) {
          this.job.loading = false;
          this.job.status = 0;
          alert('Неправильная  строка. Строка должна  быть вида: https://oauth.vk.com/blank.html#access_token=XXXXXXXX&expires_in=0')
          return;
        }
        var token_ = match[1]
      }

      console.log('checkResult ok')

      HTTP()
        .put("/job/" + id, { mode: 'check', token: token_ })
        .then((response) => {
          console.log('response', response)
          this.job.status = response.data?.response?.job?.status;
          console.log('this.job.status', this.job.status)
          window.eventBus.$emit("goldUpdate", response.data?.response?.user?.balance);
        })
        .catch((error) => {
          show_error(
            this.$notify,
            error.response.data.message || error.message
          );
        }).finally(() => {
          if (this.isMobile) {
            setTimeout(() => {
              this.token = ''
              window.eventBus.$emit("jobUpdate");
            }, 1 * 1000);
          }
        })
    },

    openWin(id, idx, link) {
      this.cooked = true;
      console.log(this.cooked);

      this.openJob(id);

      var win = window.open(
        link,
        "",
        "width=900, height=600, top=" +
        (screen.height - 600) / 2 +
        ",left=" +
        (screen.width - 900) / 2 +
        ", resizable=yes, scrollbars=yes, status=yes"
      );

      var timer = setInterval(() => {
        if (win.closed && this.curType != 'vk_token') {
          console.log("checkjob closed " + id + "" + idx + " " + link);
          console.log('this.curType', this.curType);
          if (!this.isMobile) {
            this.checkResult(id, idx);
          }
          clearInterval(timer);
        }
      }, 20);
    },
    imageLoadOnError(event) {
      console.log("imageLoadOnError", this.curNet);
      this.onerror = null;
      event.target.src = "/images/vk200.png";
    },
  },
};
</script>

<style>
.mobilebtn {
  width: 100%;
}

.descbtn {
  width: 74px;
}
</style>

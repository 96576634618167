<script>
import Step from "../pages/Step.vue";
import Login from "../pages/Login.vue";
import Register from "../pages/Register.vue";
import Logout from "../pages/Logout.vue";
import Shop from "../pages/Shop.vue";
import Support from "../pages/Support_light.vue";
import Profile from "../pages/Profile.vue";
import Referal from "../pages/Referal.vue";
import TaskList from "../pages/TaskList.vue";
import TaskForm from "../pages/TaskForm.vue";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import EmailConfirm from "../pages/EmailConfirm";
import Сoupon from "../pages/Сoupon";

const routes = [
  {
    path: "/home",
    name: "home",
    component: TaskList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/step",
    name: "step",
    component: Step,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/shop",
    name: "shop",
    component: Shop,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support",
    name: "support",
    component: Support,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/referal",
    name: "Referal",
    component: Referal,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tasknew",
    component: TaskForm,
    name: "NewTask",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/task/:id",
    component: TaskForm,
    name: "TaskEdit",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/",
    component: Step,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    component: Login,
    name: "Login",
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/register",
    component: Register,
    name: "Register",
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    name: "ForgotPassword",
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/reset-password/:email",
    component: ResetPassword,
    name: "ResetPassword",
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/email-confirm/:email",
    component: EmailConfirm,
    name: "EmailConfirm",
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/coupon",
    name: "coupon",
    component: Сoupon,
    meta: {
      requiresAuth: true,
    },
  },
];

export default routes;
</script>

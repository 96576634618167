<template> <div></div></template>

<script>
export default {
  created() {
    localStorage.setItem("access_token", "");
    location.href = "/";
  },
};
</script>

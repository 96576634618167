<template>
  <div>
    <div class="fade page-sidebar-fixed page-header-fixed show page-container" v-if="!pageOptions.pageEmpty"
      v-bind:class="{
        'page-sidebar-minified': pageOptions.pageSidebarMinified,
        'page-content-full-height': pageOptions.pageContentFullHeight,
        'page-without-sidebar': pageOptions.pageWithoutSidebar,
        'page-with-right-sidebar': pageOptions.pageWithRightSidebar,
        'page-with-two-sidebar': pageOptions.pageWithTwoSidebar,
        'page-with-wide-sidebar': pageOptions.pageWithWideSidebar,
        'page-with-light-sidebar': pageOptions.pageWithLightSidebar,
        'page-with-top-menu': pageOptions.pageWithTopMenu,
        'page-sidebar-toggled': pageOptions.pageMobileSidebarToggled,
        'page-right-sidebar-toggled':
          pageOptions.pageMobileRightSidebarToggled ||
          pageOptions.pageRightSidebarToggled,
        'has-scroll': pageOptions.pageBodyScrollTop,
      }">
      <Header />
      <TopMenu v-if="pageOptions.pageWithTopMenu" />
      <Sidebar v-if="!pageOptions.pageWithoutSidebar" />
      <SidebarRight v-if="pageOptions.pageWithTwoSidebar" />
      <div id="content" class="content" v-bind:class="{
        'content-full-width': pageOptions.pageContentFullWidth,
        'content-inverse-mode': pageOptions.pageContentInverseMode,
      }" style="margin-bottom: 90px">
        <router-view></router-view>
        <vue-ins-progress-bar></vue-ins-progress-bar>
      </div>
    </div>
    <div v-else>
      <router-view></router-view>
      <vue-ins-progress-bar></vue-ins-progress-bar>
    </div>
    <div class="d-block d-sm-none d-md-none">
      <notifications group="bottom-right" position="top center" :speed="1000" />
    </div>
    <div class="d-none d-sm-block">
      <notifications group="bottom-right" position="bottom right" :speed="1000" />
    </div>
    <Footer />
  </div>

</template>

<script>
import Sidebar from "./components/sidebar/Sidebar.vue";
import SidebarRight from "./components/sidebar-right/SidebarRight.vue";
import Header from "./components/header/Header.vue";
import TopMenu from "./components/top-menu/TopMenu.vue";
import Footer from "./components/footer/Footer.vue";
import PageOptions from "./config/PageOptions.vue";

export default {
  name: "app",
  components: {
    Sidebar,
    SidebarRight,
    Header,
    TopMenu,
    Footer,
  },
  data() {
    return {
      pageOptions: PageOptions,
    };
  },
  methods: {
    handleScroll: function () {
      PageOptions.pageBodyScrollTop = window.scrollY;
    },
    toggleMobileRightSidebar() {
      this.pageOptions.pageMobileSidebarToggled = !this.pageOptions
        .pageMobileSidebarToggled;
    },
  },
  mounted() {

    this.$insProgress.finish();

    if (localStorage.message) {
      this.$notify({
        group: "bottom-right",
        title: "",
        text: localStorage.message,
        type: "",
      });
      localStorage.message = "";
    }



    if (!!localStorage.is_fast_order && localStorage.access_token) {
      localStorage.removeItem("is_fast_order");

      this.$swal({
        title: "Вы зарегистрированы",
        html: `
      <h4 class="pt-1">Ваш заказ добавлен:</h4>
      Чтобы его посмотреть<br>  нажмите кнопку "Заказ"
      <h4 class="pt-5">Ваши данные для входа:</h4>
      <table class="table">
      <tr><td>E-mail</td><td>${localStorage.username}</td></tr>
      <tr><td>Пароль</td><td>${localStorage.password}</td></tr>
      </table>
      Для изменения E-mail или<br> пароля откройте настройки
      `,
        type: "info",
        focusConfirm: false,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Заказ",
        confirmButtonAriaLabel: "Thumbs up, great!",
        cancelButtonText: "Настройки",
        cancelButtonAriaLabel: "Thumbs down",
        /*
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Закрыть",
        confirmButtonClass: "btn m-r-5 btn-white",
        cancelButtonText: "Настройки",
        cancelButtonClass: "btn m-r-5 btn-primary",
        */
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push({ name: "home" });
        } else {
          console.log("settings");
          this.$router.push({ name: "profile" });
        }
      });
    }
  },
  created() {
    PageOptions.pageBodyScrollTop = window.scrollY;

    window.addEventListener("scroll", this.handleScroll);

    this.$insProgress.start();

    this.$router.beforeEach((to, from, next) => {
      this.$insProgress.start();
      next();
    });
    this.$router.afterEach(() => {
      this.$insProgress.finish();
    });
  },
};
</script>

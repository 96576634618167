<template>
  <panel title="Заказы:">
    <vue-good-table :columns="columns" :rows="tasks" ref="table-projects" :lineNumbers="false" :search-options="{
      enabled: true,
      placeholder: 'Поиск...',
    }" :paginationOptions="{
  enabled: true,
  nextLabel: 'Следующая',
  prevLabel: 'Предыдущая',
  rowsPerPageLabel: 'Строк на страницу',
  ofLabel: 'из',
  allLabel: 'Все',
}" :sort-options="{
  enabled: true,
  initialSortBy: { field: 'id', type: 'desc' },
}" :selectOptions="{
  enabled: false,
  selectOnCheckboxOnly: true,
  selectionInfoClass: '',
  selectionText: 'rows selected',
  clearSelectionText: 'Очистить',
}" @on-row-click="rowClicked" :row-style-class="rowStyleFn">
      <div slot="emptystate">
        <div class="text-center">
          Нет заданий...
        </div>
      </div>
      <div slot="table-actions">
        <button class="btn  btn-default m-r-10" style="height:32px" @click="addNew">
          <i class="fas fa-plus"></i>
          Добавить задание
        </button>
      </div>
      <div slot="selected-row-actions" style="margin: -2px 0;">
        <button class="btn  btn-success m-r-5 m-t-5 m-b-5" @click="projectsEdit">
          Редактировать
        </button>
        <!--<button class="btn  btn-inverse m-t-5 m-b-5">Удалить</button>-->
      </div>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'type'">
          <TaskIcon :type="props.row.type"></TaskIcon>
        </span>
        <span v-if="props.column.field == 'name'">
          {{ props.formattedRow[props.column.field] }}
        </span>
        <span v-if="props.column.field == 'progrs'">
          <ProgressBar :row="props.row"></ProgressBar>
        </span>

        <span v-if="props.column.field == 'id'">
          <div @click="projectEdit(props.row)">
            <i class="fas fa-lg fa-fw fa-pen-square fa-size text-primary"></i>
          </div>
        </span>
      </template>
    </vue-good-table>

    <div v-if="true || !isMobile()" class="text-center m-t-10">
      <!--<button
            @click.prevent="introJSStart"
            class="btn  btn-white "
            data-step="4"
            data-intro="Чтобы посмотреть это руководство еще раз  на конпку 'Помощь' "
         >
            <i class="fas fa-info-circle"></i>
            <span>Помощь</span>
         </button> -->

      <button @click="addNew" class="btn  btn-default  m-t-10 m-r-5 mobbtn">
        <i class="fas fa-plus m-r-3"></i>
        <span>Добавить задание</span>
      </button>

      <button @click.prevent="fetchData" class="btn btn-default m-t-10 m-r-5 mobbtn">
        <i class="fas fa-sync-alt m-r-3"></i>
        Обновить
      </button>

      <button @click="goStep" class="btn btn-default  m-t-10 m-r-5 mobbtn">
        <i class="far fa-heart  m-r-3"></i>
        <span>Заработать лайки</span>
      </button>
    </div>
  </panel>
</template>
<script>
import HTTP from "../http";
import { show_error } from "../message";

export default {
  name: "my-component",
  data() {
    return {
      error: null,
      tasks: [],
      loading: false,
      selectedRow: -1,

      columns: [
        {
          label: "",
          field: "type",
          width: "20px",
          thClass: "text-nowrap td-visible",
          tdClass: "text-nowrap td-visible",
        },
        {
          label: "Задание",
          field: "name",
          width: "40%",
          thClass: "text-nowrap td-width",
          tdClass: "text-nowrap td-width",
        },
        {
          label: "Статус",
          field: "progrs",
          thClass: "text-nowrap td-visible",
          tdClass: "text-nowrap td-visible",
        },
        {
          label: "",
          field: "id",
          type: "number",

          width: "40px",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
    };
  },

  created() {
    this.fetchData();
    //console.log("Commit number: " + process.env.MIX_VERSION);

  },

  mounted() { },

  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent //|| window.innerWidth < 600
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    rowStyleFn(row) {
      return row.originalIndex === this.selectedRow
        ? "bg-blue-transparent-1"
        : "";
    },
    rowClicked(params) {
      this.selectedRow = params.row.originalIndex;
      this.projectEdit(params.row);
    },
    projectsEdit() {
      if (this.$refs["table-projects"].selectedRows[0]) {
        this.projectEdit(this.$refs["table-projects"].selectedRows[0]);
      }
    },
    projectEdit(row) {
      console.log(row, 'row')
      localStorage.edit_task = JSON.stringify(row);
      this.$router.push({ name: "TaskEdit", params: { id: row.id } });
    },
    fetchData() {
      this.error = null;
      this.tasks = [];
      this.loading = true;

      HTTP()
        .get("/task")
        .then((response) => {
          this.loading = false;
          this.tasks = response.data.tasks;
          window.eventBus.$emit("goldUpdate", String(response.data.user.balance));
          console.log('balance', response.data.user.balance)
        })
        .catch((error) => {
          this.loading = false;
          show_error(
            this.$notify, error.response.data
          );
        });
    },
    addNew() {
      this.$router.push({ name: "NewTask" });
    },
    goStep() {
      this.$router.push({ name: "step" });
    },
  },
};
</script>

<style>
.progress-height {
  margin-top: 2px;
  height: 18px !important;
}

.fa-size {
  font-size: 1.8em;
}

.selectedRow {
  background-color: red;
}

@media (max-width: 767px) {
  .td-visible {
    display: none;
  }

  .td-width {
    min-width: 80% !important;
    max-width: 80% !important;
    width: 80% !important;
  }

  .mobbtn {
    width: 100%;
  }
}
</style>

<template>
  <tr>
    <td>{{ fieldname }}</td>
    <td v-if="net == 'vk'">
      <div class="radio radio-css">
        <input type="radio" name="radio_css" id="radio_2" v-model="type" value="vk_like"
          @click="changeRecord('vk_like')" :disabled="!isnewtask || !isliked" />
        <label for="radio_2" :class="{ label_disabled: !isnewtask || !isliked }">Накрутить лайки</label>
      </div>
      <div class="radio radio-css">
        <input type="radio" name="radio_css" id="radio_3" v-model="type" value="vk_repost"
          @click="changeRecord('vk_repost')" :disabled="!isnewtask || !isliked" />
        <label for="radio_3" :class="{ label_disabled: !isnewtask || !isliked }">
          Накрутить репосты</label>
      </div>
      <div class="radio radio-css">
        <input type="radio" name="radio_css" id="radio_4" v-model="type" value="vk_cmnt"
          @click="changeRecord('vk_cmnt')" :disabled="!isnewtask || !isliked" />
        <label for="radio_4" :class="{ label_disabled: !isnewtask || !isliked }">
          Накрутить комментарии</label>
      </div>
      <!--<div class="radio radio-css">
        <input type="radio" name="radio_css" id="radio_5" v-model="type" value="vk_poll"
          @click="changeRecord('vk_poll')" :disabled="!isnewtask || !isliked" />
        <label for="radio_5" :class="{ label_disabled: !isnewtask || !isliked }">
          Накрутить опрос</label>
      </div>-->
      <div class="radio radio-css">
        <input type="radio" name="radio_css" id="radio_1" v-model="type" value="vk_subs"
          @click="changeRecord('vk_subs')" :disabled="!isnewtask || isliked" />
        <label for="radio_1" :class="{ label_disabled: !isnewtask || isliked }">Накрутить подписчиков</label>
      </div>
    </td>

    <td v-else-if="net == 'in'">
      <div class="radio radio-css">
        <input type="radio" name="radio_css" id="radio_2" v-model="type" value="in_like"
          @click="changeRecord('in_like')" />
        <label for="radio_2" :class="{ label_disabled: !isnewtask || !isliked }">Накрутить лайки</label>
      </div>
      <div class="radio radio-css">
        <input type="radio" name="radio_css" id="radio_3" v-model="type" value="in_comnt"
          @click="changeRecord('in_comnt')" />
        <label for="radio_3" :class="{ label_disabled: !isnewtask || !isliked }">Накрутить комметнарии</label>
      </div>
      <div class="radio radio-css">
        <input type="radio" name="radio_css" id="radio_1" v-model="type" value="in_user"
          @click="changeRecord('in_user')" />
        <label for="radio_1" :class="{ label_disabled: !isnewtask || isliked }">Накрутить подписчиков</label>
      </div>
    </td>

    <td v-else-if="net == 'yt'">
      <div class="radio radio-css">
        <input type="radio" name="radio_css" id="radio_1" v-model="type" value="yt_like"
          @click="changeRecord('yt_like')" :disabled="!isnewtask || !isliked" />
        <label for="radio_1" :class="{ label_disabled: !isnewtask || !isliked }">Накрутить лайки</label>
      </div>
      <div class="radio radio-css">
        <input type="radio" name="radio_css" id="radio_2" v-model="type" value="yt_dlke"
          @click="changeRecord('yt_dlke')" :disabled="!isnewtask || !isliked" />
        <label for="radio_2" :class="{ label_disabled: !isnewtask || !isliked }">Накрутить дизлайки</label>
      </div>
      <div class="radio radio-css">
        <input type="radio" name="radio_css" id="radio_3" v-model="type" value="yt_cmnt"
          @click="changeRecord('yt_cmnt')" :disabled="!isnewtask || !isliked" />
        <label for="radio_3" :class="{ label_disabled: !isnewtask || !isliked }">Накрутить комметнарии</label>
      </div>
      <div class="radio radio-css">
        <input type="radio" name="radio_css" id="radio_3" v-model="type" value="yt_user"
          @click="changeRecord('yt_user')" :disabled="!isnewtask || isliked" />
        <label for="radio_3" :class="{ label_disabled: !isnewtask || isliked }">Накрутить подписчиков</label>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: "TaskTypeRow",
  props: ["isnewtask", "id", "record", "apiname", "fieldname"],
  data() {
    return { type: "vk_like", net: "vk", isliked: true };
  },

  mounted() {
    if (!this.isnewtask) {

      this.type = this.record;
      console.log('this.type', this.type)
      if (this.type == "vk_group" || this.type == "vk_frnd") {
        this.type = "vk_subs";
      }

      this.net = this.type.charAt(0) + this.type.charAt(1);

      console.log("this.record", this.net);
    }

    window.eventBus.$on("link_chaned", (link) => {
      console.log('link', link)
      this.net = link.net;
      this.isliked = link.isliked;

      if (this.net == "vk") {
        this.type = link.isliked ? "vk_like" : "vk_subs";
      }

      if (this.net == "in") {
        this.type = link.isliked ? "in_like" : "in_user";
      }

      if (this.net == "yt") {
        this.type = link.isliked ? "yt_like" : "yt_user";
      }

      this.changeRecord(this.type);
      console.log("this.net", this.net);
    });
  },
  methods: {
    changeRecord(type) {
      if (localStorage.edit_task != null) {
        let task = JSON.parse(localStorage.edit_task);
        task[this.apiname] = type;
        localStorage.edit_task = JSON.stringify(task);
      } else {
        let task = { [this.apiname]: type };
        localStorage.edit_task = JSON.stringify(task);
      }
      if (type == "vk_poll") {
        window.eventBus.$emit("poll_selected");
      }
      window.eventBus.$emit("task_changed", type);
    },
  },
};
</script>

<style>
.label_disabled {
  opacity: 0.6;
}
</style>

<template>
  <div class="row">
    <div class="col-md-12 ui-sortable">
      <panel title="Купить лайки">
        <div v-if="!enabled" class="text-center bg-silver m-20 p-20">
          <h4>Модуль временно отключен</h4>
        </div>
        <div v-else-if="loading" class="text-center bg-silver m-20 p-20">
          <h4>Загружаем....</h4>
        </div>
        <div v-else-if="error" class="text-center bg-silver m-20 p-20">
          <h4>{{ error }}</h4>
        </div>
        <div v-else-if="tradeOffers.length">
          <h2>Купить лайки:</h2>
          <p>
            Нажмите на кнопку "Выбрать" напротив нужного количества лайков:
          </p>
          <table id="buy_table" class="table table table-hover ">
            <thead>
              <tr>
                <th>Лайки</th>
                <th class="d-none d-sm-block">Бонус</th>
                <th>Цена</th>
                <th>Покупка</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(tradeOffer, index) in tradeOffers" :key="index" v-bind:class="{
                'bg-blue-transparent-2': sum == tradeOffer.sum,
              }" @click="setSum(index)">
                <td>{{ tradeOffer.likes }} лайков</td>
                <td v-if=(tradeOffer.bonus) class="d-none d-sm-block">
                  <span class="badge badge-success f-s-10 m-r-5 m-l-5">+Бонус {{ tradeOffer.bonus }} %
                    <i class="fa fa-heart-o"></i></span><br>
                  +{{ tradeOffer.bonusLikes }} лайков
                </td>
                <td v-else class="d-none d-sm-block">

                </td>
                <td>{{ tradeOffer.sum }} рублей</td>
                <td>
                  <button @click="setSum(index)" class="btn btn-sm btn-primary m-l-10 m-4">
                    <b>Выбрать</b>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <h3>Или введите сумму:</h3>
          <form class="form-horizontal m-r-10" onsubmit="return false" data-parsley-validate="true" novalidate="">
            <div class="form-group">
              <label class="col-md-4 control-label">Рубли:</label>
              <div class="col-md-12">
                <input type="text" class="form-control" id="rub" placeholder="от 5 до 14 000"
                  data-parsley-range="[1,14000]" data-parsley-type="number" data-parsley-required="true"
                  data-parsley-id="1870" v-model="sum" @keyup="getOffer">
                <ul class="parsley-errors-list" id="parsley-id-1870"></ul>
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-4 control-label">Лайки:</label>
              <div class="col-md-12">
                <input disabled="disabled" type="text" class="form-control" id="likes" placeholder="от 25 до 100 000"
                  data-parsley-range="[25,100000]" data-parsley-type="number" data-parsley-required="true"
                  data-parsley-id="6373" v-model="likes">
                <ul class="parsley-errors-list" id="parsley-id-6373"></ul>
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-4 control-label">Бонус(%)</label>
              <div class="col-md-12">
                <input disabled="disabled" type="text" class="form-control" id="disc" placeholder="%"
                  data-parsley-id="1787" v-model="bonus">
                <ul class="parsley-errors-list" id="parsley-id-1787"></ul>
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-4 control-label">Бонусные лайки:</label>
              <div class="col-md-12">
                <input disabled="disabled" type="text" class="form-control" id="bonus" placeholder="в подарок"
                  data-parsley-id="5065" v-model="bonusLikes">
                <ul class="parsley-errors-list" id="parsley-id-5065"></ul>
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-4 control-label">Итог, лайков:</label>
              <div class="col-md-12">
                <input disabled="disabled" type="text" class="form-control" id="itog" placeholder="итог"
                  data-parsley-id="5999" v-model="itogLikes">
                <ul class="parsley-errors-list" id="parsley-id-5999"></ul>
              </div>
            </div>
          </form>


          <div v-if="sum == 0" class="text-center bg-silver m-20 p-20">
            Количество лайков не выбрано. Пожалуйста нажмите "Выбрать" напротив
            нужного количества лайков.
          </div>
          <div v-else>
            <h2>Ваш заказ:</h2>
            <blockquote style="font-size: 16px;">
              Вы покупаете: {{ likes }} лайков <br />
              Вы уже купили лайков на сумму: {{ payedSum }} руб.<br />
              C учетом этой покупки сумма: {{ payedAll }} руб.<br />
              Бонус на эту сумму: {{ bonus }}%<br />
              Вы получите бонусом: +{{ bonusLikes }} лайков <br />
              С учетом бонуса получается : {{ itogLikes }} лайков <br />
              <b>Итого к оплате: {{ sum }} руб. за {{ itogLikes }} лайков </b><br />
            </blockquote>
            <!--<div class="alert alert-warning ">
              <strong>Внимание: </strong>
              Некоторые платежи (например с мобильных телефонов)
              могут обрабатываются с задержкой (обычно до 5 минут)
            </div>-->

            <h4>Укажите ваш Email:</h4>
            <ul>
              <li>
                Для покупки в поле ниже укажите ваш емайл и нажмите "Купить"
              </li>
              <li>
                Емайл будет использовант только один раз - для отправки вам
                электронного чека, подтверждающего покупку
              </li>
            </ul>
            <div class="text-center m-5 p-5">
              <div class="input-group ">
                <input type="text" class="form-control" placeholder="mail@example.com" v-model="email" />
                <div class="input-group-btn">
                  <button type="button" class="btn btn-primary" @click="buyCurrentSum()">
                    <b>Купить</b>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="payError" class="text-center bg-silver m-20 p-20">
            <h4>{{ payError }}</h4>
          </div>
          <div v-if="payInfo" class="text-center bg-silver m-20 p-20">
            <h4 v-html="payInfo"></h4>
          </div>


          <div v-if="payments && payments.length">
            <h2>Ваши покупки:</h2>
            <table class="table table table-hover ">
              <thead>
                <tr>
                  <th>id</th>
                  <th>Email(для чека)</th>
                  <th>Лайки</th>
                  <th>Сумма</th>
                  <th>Статус</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(purchase, index) in payments" :key="index">
                  <td>{{ purchase.id }}</td>
                  <td>{{ purchase.email }}</td>
                  <td>{{ purchase.likes }} ({{ purchase.bonus }}%)</td>
                  <td>{{ purchase.sum }}</td>
                  <td v-if="purchase.status == 1">
                    <span class="label label-success">Оплачено</span>
                  </td>
                  <td v-else>
                    <span class="label label label-info">Ожидает оплаты</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3>Зачем покупать лайки?</h3>
          <ul>
            <li>
              Вам не нужно тратить драгоценное время на зарабатывание лайков
            </li>
            <li>
              Вам не нужно больше просматривать надоедливые рекламные объявления,
              захламлять свою стену репостами или подписываться на неинтересные
              вам группы
            </li>
            <li>Вы поможете сделать наш сервис еще лучше</li>
          </ul>

          <h3>Бонусы для постоянных клиентов:</h3>

          <p>
            Накопительная система скидок для постоянных клиентов - чем больше вы
            покупаете, тем больше получаете лайков в подарок!
          </p>

          <div>
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td>Суммарная покупка, от (руб):</td>
                  <td>Бонус (%)</td>
                </tr>
                <tr>
                  <td>
                    <div style="text-align:center">500</div>
                  </td>
                  <td>
                    <div style="text-align:center">5%</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style="text-align:center">2500</div>
                  </td>
                  <td>
                    <div style="text-align:center">10%</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style="text-align:center">5000</div>
                  </td>
                  <td>
                    <div style="text-align:center">15%</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style="text-align:center">10000</div>
                  </td>
                  <td>
                    <div style="text-align:center">20%</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style="text-align:center">25000</div>
                  </td>
                  <td>
                    <div style="text-align:center">25%</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style="text-align:center">50000</div>
                  </td>
                  <td>
                    <div style="text-align:center">30%</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style="text-align:center">100000</div>
                  </td>
                  <td>
                    <div style="text-align:center">35%</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </panel>
    </div>
  </div>
</template>

<script>
import HTTP from "../http";
import { show_error } from "../message";

export default {
  data() {
    return {
      enabled: true,
      loading: false,
      error: "",

      likes: 0,
      sum: 0,
      payedSum: 0,
      payedAll: 0,
      bonus: 0,
      bonusLikes: 0,
      itogLikes: 0,

      likes_4_rub: 0, // for  1 ruble  we give  5 likes

      email: "",
      payError: "",
      payInfo: "",

      tradeOffers: [],
      payments: [],
    };
  },
  created() {
    this.fetchData();
    //this.setSum(100);
    this.getOffer(1000);
  },

  methods: {
    fetchData() {
      this.error = null;
      this.loading = true;
      this.$insProgress.start();
      HTTP()
        .get("shop")
        .then((response) => {
          this.loading = false;
          this.payedSum = response.data.user.sum;
          this.likes_4_rub = response.data.shop.likes_4_rub;
          this.email = response.data.user.email;
          this.tradeOffers = response.data.shop.offers;
          this.payments = response.data.shop.payments;
          this.enabled = response.data.shop.is_open == 'true';
          this.$insProgress.finish();
        })
        .catch((error) => {
          this.loading = false;
          show_error(
            this.$notify, error.response.data
          );
          this.$insProgress.finish();
        });
    },

    getOffer() {
      HTTP()
        .get("offer?sum=" + this.sum)
        .then((response) => {
          this.loading = false;
          this.sum = response.data.sum;
          this.bonus = response.data.bonus;
          this.likes = response.data.likes;
          this.bonusLikes = response.data.bonusLikes;
          this.itogLikes = response.data.itogLikes
        })
        .catch((error) => {
          this.loading = false;
          show_error(
            this.$notify, error.response.data
          );
        });
    },


    buyCurrentSum() {
      this.payError = null;
      this.$insProgress.start();
      HTTP()
        .post("shop", { sum: this.sum, email: this.email })
        .then((response) => {
          console.log('response', response)
          this.payInfo =
            `<div style="display:flex;flex-direction:row;  align-items: center;justify-content: space-between;">
            <div>Заказ на сумму ${response.data.shop.sum} руб. создан:</div> 
            <div><a href='${response.data.payment.link}' target="_blank" type="button" class="btn btn-info mt-3"> Оплатить</a></div>
            </div> `;
          this.payments = response.data.shop.payments;
          this.$insProgress.finish();
        })
        .catch((error) => {
          this.loading = false;
          show_error(
            this.$notify, error.response.data
          );
          this.$insProgress.finish();
        });
    },

    setSum(index) {
      this.sum = this.tradeOffers[index].sum;
      this.likes = this.tradeOffers[index].likes;
      this.payedAll = this.payedSum + this.sum;
      this.bonus = this.tradeOffers[index].bonus;
      this.bonusLikes = this.tradeOffers[index].bonusLikes;
      this.itogLikes =
        this.tradeOffers[index].bonusLikes + this.tradeOffers[index].likes;
    },
  },
};
</script>

<template>
  <div>
    <panel :title="title">
      <div class="alert alert-success fade show">
        Здесь можно добавлять только задания для сайта ВКонтакте. Для продвижения
        instagram, facebook, youtube или других
        попробуйте <a href='http://fastsmm.ru' target='_blank'>fastsmm.ru</a>
      </div>
      <table class="table table-hover">
        <tbody>
          <TaskInputRow :isnewtask="isNewTask" :id="task.id" :record="task.name" :fieldname="`Название`"
            :apiname="`name`" :message="`Название изменено`" :help="
              `Название может быть любое. Оно необходимо, чтобы вы не запутались в заказах`
            " data-step="1" data-intro="Название может быть любое. Оно необходимо, чтобы вы не запутались в заказах">
          </TaskInputRow>

          <TaskLinkRow :isnewtask="isNewTask" :id="task.id" :record="task.link" :fieldname="`Ссылка на:`"
            :apiname="`link`" :help="
              `Откройте страницу с заданием, скопируйте сюда ссылку из адресной строки браузера.`
            " data-step="2"
            data-intro="Ссылка на задание. Откройте страницу с заданием, скопируйте сюда ссылку из адресной строки браузера.">
          </TaskLinkRow>
          <TaskTypeRow :isnewtask="isNewTask" :id="task.id" :record="task.type" :fieldname="`Тип задания`"
            :apiname="`type`"></TaskTypeRow>
          <TaskPollRow :isnewtask="isNewTask"></TaskPollRow>

          <TaskInputRow :isnewtask="isNewTask" :id="task.id" :record="task.price" :fieldname="`Цена`" :apiname="`price`"
            :type="task.type" :message="`Цена изменена`" :help="`Чем выше цена тем быстрее выполняется заказ`"
            data-step="3" data-intro="Цена задания. Чем выше цена тем быстрее выполняется заказ "></TaskInputRow>
          <tr v-if="!isNewTask">
            <td>Количество[сделано]</td>
            <td>
              <input class="form-control" type="text" v-model="task.done" disabled />
            </td>
          </tr>
          <TaskInputRow :isnewtask="isNewTask" :id="task.id" :record="task.count"
            :fieldname="isNewTask ? `Количество` : `Количество[осталось]`" :apiname="`count`"
            :message="`Количество изменено`" :help="`Сколько сталось сделать`" data-step="4"
            data-intro="Здесь указывается необходимое количество выполнений заказа "></TaskInputRow>

          <tr v-if="isNewTask">
            <td>Спишется с баланса :</td>
            <td>
              <label class="label label-success f-s-12">{{ task.calculated }}
                <i class="far fa-heart"></i>
              </label>
            </td>
          </tr>

          <tr v-if="!isNewTask">
            <td>Прогресс :</td>
            <td>
              <ProgressBar :row="task"></ProgressBar>
            </td>
          </tr>

          <tr v-if="!isNewTask">
            <td>Статус</td>
            <td v-if="task.count == 0">
              <div class="alert alert-success">
                Задание выполнено
              </div>
            </td>
            <td v-else-if="task.pass < 5">
              <div v-if="curprice >= optprice" class="alert alert-primary">
                Задание выполняется, цена оптимальная
              </div>
              <div v-else class="alert alert-yellow">
                Задание выполняется, цена {{ curprice }} ниже оптимальной
                {{ optprice }}. Чтобы задание выполнялось быстрее поставьте цену
                равную {{ optprice }}
              </div>
            </td>
            <td v-else>
              <div class="alert alert-warning">
                Задание остановлено, код {{ task.pass + task.ban_id }}, <br />
                <div v-show="task.ban">
                  {{ task.ban }}
                </div>
                <div v-show="!task.ban">
                  Причина остановки: Страница недоступна или приватная<br />
                  Проверьте доступность страницы
                </div>
                <div class="text-center  m-t-5">
                  <button class="btn btn-default btn-xs" @click="unBan(task.id)">
                    Снять бан
                  </button>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td colspan="2" class="text-center">
              <div v-if="isNewTask" class="btn btn-primary m-t-10 mobbtn m-r-5" @click.prevent="addTask">
                <span class="fa fa-plus m-r-3"></span>&nbsp;Создать
              </div>
              <div v-else class="btn btn-default m-t-10 mobbtn m-r-5" @click.prevent="removeTask">
                <span class="fa fa-trash-alt m-r-3"></span>&nbsp;Удалить
              </div>

              <div class="btn  btn-default m-t-10 mobbtn m-r-5" @click="introJSStart">
                <i class="fas fa-info-circle m-r-3"></i>
                <span>Помощь</span>
              </div>

              <div class="btn btn-white m-t-10 mobbtn" @click.prevent="onCancel">
                <span class="fa fa-times"></span>&nbsp;Отмена
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </panel>
  </div>
</template>

<script>
import HTTP from "../http";
import { show_message, show_error } from "../message";

export default {
  data() {
    return {
      isNewTask: false,
      title: "",
      task: {
        name: "",
        link: "",
        type: "",
        price: 0,
        count: 0,
        calculated: 0,
      },
      edit: {
        name: false,
      },
      field: { 0: "name" },
      optprice: 50,
      curprice: 0,
    };
  },

  mounted() {
    window.eventBus.$on("record_changed", () => {
      if (localStorage.edit_task) {
        let task = JSON.parse(localStorage.edit_task);
        this.task.calculated =
          task.price && task.count
            ? parseInt(task.price) * parseInt(task.count)
            : 0;
      }
    });

    this.curprice = this.task.price;

    /*
    window.eventBus.$on("optPriceUpdate", (price) => {
      this.optprice = price;
    });
*/
    window.eventBus.$on("curPriceUpdate", (price) => {
      this.curprice = price;
    });

    if (!localStorage.introJS_TaskForm) {
      this.introJSStart();
    }
  },
  created() {
    console.log(this.$route.params);
    if (!this.$route.params.id) {
      this.title = "Создать новое задание";
      this.isNewTask = true;
      localStorage.removeItem("edit_task");
    } else {
      this.isNewTask = false;
      this.title = "Редактировать задание";
      this.task = JSON.parse(localStorage.edit_task);
    }
  },

  methods: {
    introJSStart() {
      const introJS = require("intro.js");
      //onexit oncomplete
      introJS
        .introJs()
        .onexit(function () {
          localStorage.setItem("introJS_TaskForm", true);
        })
        .start();
    },

    removeTask() {

      if (confirm("Вы уверены? После удаления заказа неиспользованные лайки будут возвращены на ваш баланс")) {
        HTTP()
          .delete("task/" + this.task.id)
          .then((response) => {
            console.log(response);
            this.loading = false;
            this.$router.push({ name: "home" });
            window.eventBus.$emit("goldUpdate", response.data.response.user.balance);
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            this.error = error.response.data.message || error.message;
          });
        alert('Заказ удален')
      }
      return;

      /*
        this.$swal({
          title: "Вы уверены?",
          text:
            "После удаления заказа неиспользованные лайки будут возвращены на ваш баланс",
          type: "error",
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: "Удалить",
          cancelButtonText: "Отмена",
          confirmButtonClass: "btn m-r-5 btn-danger",
          cancelButtonClass: "btn btn-default",
        }).then((result) => {
          if (result.value) {
            HTTP()
              .delete("task/" + this.task.id)
              .then((response) => {
                console.log(response);
                this.loading = false;
                this.$router.push({ name: "home" });
                window.eventBus.$emit("goldUpdate", response.data.response.user.balance);
              })
              .catch((error) => {
                console.log(error);
                this.loading = false;
                this.error = error.response.data.message || error.message;
              });
            this.$swal.fire("Удалено!", "Заказ удален", "success");
          } else {
            this.$swal.fire("Отмена", "", "error");
          }
        });
        */
    },

    addTask: function () {
      if (!localStorage.edit_task) {
        show_error(this.$notify, "Введите название ");
        return;
      }
      HTTP()
        .post("/task", JSON.parse(localStorage.edit_task))
        .then((response) => {
          show_message(this.$notify, "Задание добавлено");
          window.eventBus.$emit("goldUpdate", response.data.response.user.balance);
          this.$router.push({ name: "home" });

        })
        .catch((error) => {
          console.log('error', error)
          show_error(
            this.$notify, error.response.data
          );
        });
    },

    unBan: function (id) {
      HTTP()
        .put(`unban/${id}`)
        .then((response) => {
          show_message(this.$notify, response.data || "Бан снят...");
          this.$router.push({ name: "home" });
        })
        .catch((error) => {
          show_error(
            this.$notify, error.response.data
          );
        });
    },

    onCancel() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style>
.fa-sz {
  font-size: 1.8em;
  margin-top: 4px;
}

.label-size {
  font-size: 125% !important;
}
</style>

<template>
  <tr>
    <td @click="showmessage(help)">
      <span v-if="apiname == 'price'">
        {{ fieldname }}
      </span>
      <span v-else>
        {{ fieldname }}
      </span>
      <b class="pull-right pt-1">
        <div class="btn btn-xs btn-white">
          <i class="fas fa-info-circle"></i>
        </div>
      </b>
    </td>
    <td>
      <div v-if="!isRecordEdit" @click="recordStartEdit">
        <div class="input-group ">
          <input class="form-control" type="text" v-model="value" disabled style="opacity:1" />
          <div class="input-group-append" @click="recordEndEdit">
            <span class="input-group-text"><i class="fa fa-pencil-alt"></i></span>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="input-group ">
          <input class="form-control" @keyup.enter="recordEndEdit" @keyup.esc="recordCancelEdit" @blur="recordEndEdit"
            type="text" v-model="value" v-focus />
          <div class="input-group-append" @click="recordEndEdit">
            <span class="input-group-text"><i class="fa fa-save"></i></span>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import HTTP from "../http";
import { show_message, show_error } from "../message";

export default {
  name: "EditableRow",
  props: ["id", "record", "apiname", "fieldname", "message", "help", "type"],
  data() {
    return {
      isRecordEdit: false,
      oldRecordValue: "",
      value: "",
      optprice: 50,
    };
  },
  created() {
    this.value = this.record;

    /*
    if (this.type) {
      HTTP()
        .get("task/price/" + this.type)
        .then((response) => {
          const price = Math.floor(response.data[0].price);
          this.optprice = Math.max(price, this.value);
          window.eventBus.$emit("optPriceUpdate", this.optprice);
        })
        .catch((error) => {
          show_error(
            this.$notify,
            error.response.data.message || error.message
          );
        });
    }*/
  },
  directives: {
    focus: {
      // directive definition
      inserted: function (el) {
        el.focus();
      },
    },
  },
  methods: {
    showmessage(message) {
      show_message(this.$notify, message);
    },
    recordStartEdit() {
      this.isRecordEdit = true;
      this.oldRecordValue = this.value;
    },
    recordCancelEdit() {
      this.value = this.oldRecordValue;
      this.isRecordEdit = false;
    },
    recordEndEdit() {
      if (!this.isRecordEdit) return;
      this.isRecordEdit = false;

      if (this.oldRecordValue != this.value) {
        HTTP()
          .put("task/" + this.id, { [this.apiname]: this.value })
          .then((response) => {
            window.eventBus.$emit("goldUpdate", String(response.data.response.user.balance));
            show_message(this.$notify, this.message);

            if (this.apiname == "price") {
              window.eventBus.$emit("curPriceUpdate", this.value);
            }
          })
          .catch((error) => {
            show_error(
              this.$notify, error.response.data
            );
          });
      }
      this.isRecordEdit = false;
      window.eventBus.$emit("record_changed");
    },
  },
};
</script>

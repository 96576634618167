<template>
  <!-- begin login -->
  <div class="login login-with-news-feed" style="height:100%">
    <!-- begin news-feed -->
    <div class="news-feed">
      <div class="news-image" style="background-image: url(/images/bg.jpg)"></div>
      <div class="news-caption">
        <h4 class="caption-title">{{ siteName }}</h4>
        <p>
          Накрутка лайков, друзей, подписчиков
        </p>
      </div>
    </div>
    <!-- end news-feed -->
    <!-- begin right-content -->
    <div class="right-content d-flex flex-column justify-content-center">
      <!-- begin login-header -->
      <div class="text-center m-t-20"></div>
      <!-- end login-header -->

      <div class="login-content">
        <h1 class="text-center">{{ siteName }}</h1>
        <div v-if="token">
          <p>Введите новый пароль:</p>
          <div class="hidt">
            <div class="mt-3">
              <div class="form-group m-b-15">
                <input type="password" class="form-control form-control-lg" placeholder="пароль" required=""
                  autocomplete="off" v-model="password" />
              </div>
              <div class="form-group m-b-15">
                <input type="password" class="form-control form-control-lg" placeholder="повторите пароль" required=""
                  autocomplete="off" v-model="password_confirmation" />
              </div>
            </div>

            <div class="login-buttons mt-3">
              <button @click="reset" class="btn btn-primary btn-block btn-lg" :disabled="loading">
                <i v-if="loading" class="fas fa-circle-notch fa-w-16 fa-fw fa-spin"></i>
                Сбросить пароль
              </button>
            </div>
            <div class="m-t-20 m-b-40 p-b-40 text-inverse">
              Есть аккаунт? <a href="/#/login">Войти</a><br>
              Нет аккаунта? <a href="/#/register">Регистрация</a>
            </div>
          </div>
        </div>
        <div v-else class="text-center">
          <div class="alert alert-primary fade show">
            Время действия ссылки истекло. <br>Попробуйте восстановить пароль еще раз<br>
            <a type="button" href="/#/forgot-password" class="btn btn-primary btn-sm  mt-3">Восстановить пароль</a>
          </div>

        </div>
      </div>
      <div class="text-center">
        Не получается войти?<br />
        Пишите:
        <a href="mailto:support.turboliker@ya.ru">support.turboliker@ya.ru</a>
      </div>
    </div>
    <!-- end right-container -->
  </div>
  <!-- end login -->
</template>

<script>
import PageOptions from "../config/PageOptions.vue";
import HTTP from "../http";
import { show_error } from "../message";

export default {
  data() {
    return {
      password: "",
      password_confirmation: "",
      loading: false,
      token: "",
      email: "",
      siteName: ''
    };
  },

  created() {
    this.siteName = process.env.VUE_APP_SITE_NAME
    //document.body.className = "bg-white"; 
    console.log("page loaded ", this.$route.query.signature);
    console.log("page loaded ", this.$route.params.email);

    this.email = this.$route.params.email
    this.checkSignature();
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {
    checkForm: function () { },

    checkSignature() {
      this.$insProgress.start();
      this.loading = true;
      HTTP()
        .get("reset-password/" + this.$route.params.email + '?signature=' + this.$route.query.signature)
        .then((resp) => {
          this.token = resp.data
          console.log('resp', resp)
        })
        .catch((error) => {
          console.log('error', error);
          console.log('error.message', error.message);
          console.log('error.response.data', error.response.data);
          show_error(
            this.$notify, error.response.data
          );
          this.loading = false;
          this.$insProgress.finish();
        })
        .finally(() => {
          this.loading = false;
        })
    },

    reset() {
      this.$insProgress.start();
      this.loading = true;
      HTTP()
        .post("reset-password/", {
          email: this.email, token: this.token, password: this.password, password_confirmation: this.password_confirmation
        })
        .then((resp) => {
          console.log('resp', resp)
          localStorage.setItem("access_token", resp.data.token.token);
          localStorage.setItem("photo", resp.data.user.photo);
          localStorage.setItem("balance", resp.data.user.balance);
          localStorage.setItem("message", resp.data.message);
          localStorage.setItem("id", resp.data.user.id);
          location.href = "/";
        })
        .catch((error) => {
          console.log('error', error);
          console.log('error.message', error.message);
          console.log('error.response.data', error.response.data);
          show_error(
            this.$notify, error.response.data
          );
          this.loading = false;
          this.$insProgress.finish();
        })
        .finally(() => {
          this.loading = false;
        })
    },
  },
};
</script>


<style>
.hidt {
  min-width: 300px
}
</style>
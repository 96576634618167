<template>
  <tr>
    <td @click="showmessage(help)">
      <span v-if="apiname == 'price'">
        {{ fieldname }}
      </span>
      <span v-else>
        {{ fieldname }}
      </span>
      <b class="pull-right pt-1">
        <div class="btn btn-xs btn-white">
          <i class="fas fa-info-circle"></i>
        </div>
      </b>
    </td>
    <td>
      <div v-if="!isRecordEdit" @click="recordStartEdit">
        <div class="input-group">
          <input type="text" class="form-control">
          <div class="input-group-text"><i class="fa fa-calendar"></i></div>
        </div>
        <div class="input-group ">
          <input class="form-control" :type="input_type" v-model="value" disabled style="opacity:1" />
          <div class="input-group-append" @click="recordEndEdit">
            <span class="input-group-text"><i class="fa fa-pencil-alt"></i></span>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="input-group ">
          <input class="form-control" @keyup.enter="recordEndEdit" @keyup.esc="recordCancelEdit" @blur="recordEndEdit"
            :type="input_type" v-model="value" v-focus />
          <div class="input-group-append" @click="recordEndEdit">
            <span class="input-group-text"><i class="fa fa-save"></i></span>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import HTTP from "../http";
import { show_message, show_error } from "../message";

export default {
  name: "ProfileEditableRow",
  props: ["record", "apiname", "fieldname", "message", "help", "type"],
  data() {
    return {
      isRecordEdit: false,
      oldRecordValue: "",
      value: "",
      optprice: 50,
      input_type: "text",
    };
  },
  created() {
    this.value = this.record;

    if (this.apiname == "password") {
      this.input_type = "password";
    }
  },
  directives: {
    focus: {
      // directive definition
      inserted: function (el) {
        el.focus();
      },
    },
  },
  methods: {
    showmessage(message) {
      show_message(this.$notify, message);
    },
    recordStartEdit() {
      this.isRecordEdit = true;
      if (this.apiname == "password") {
        this.value = "";
      }
      this.oldRecordValue = this.value;
    },
    recordCancelEdit() {
      this.value = this.oldRecordValue;
      this.isRecordEdit = false;
    },
    recordEndEdit() {
      if (!this.isRecordEdit) return;
      this.isRecordEdit = false;

      if (this.oldRecordValue != this.value) {
        HTTP()
          .put("/user/" + (localStorage.id || 0), { [this.apiname]: this.value })
          .then(() => {
            show_message(this.$notify, this.message);
            if (this.apiname == "name") {
              localStorage.setItem("userName", this.value);
              location.reload();
            }
          })
          .catch((error) => {
            console.log("error.message", error.response.data.errors);
            let errors = error.response.data.errors;

            var error_message = "Ошибка редактирования:";

            if (errors.email) {
              error_message = error_message + " " + errors.email[0];
            }

            if (errors.password) {
              error_message = error_message + " " + errors.password[0];
            }

            show_error(this.$notify, error_message);
            this.loading = true;
          });
      }
      this.isRecordEdit = false;
      window.eventBus.$emit("record_changed");
    },
  },
};
</script>

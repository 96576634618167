<template>
  <panel title="Рефералы">
    <div v-if="data">
      <h3>Ваши рефералы:</h3>

      <p>
        Рефералы - это пользователи, которые первый раз зашли на сайт по вашей
        реферальной ссылке.
      </p>

      <p>
        Вы получаете <b>+{{ data.percent }}%</b> от всех заработанных вашими
        реальными рефералами лайков. Бонус рассчитывается раз в 24 часа.
      </p>

      <h3>Как набрать рефералов?</h3>

      <p>
        Чтобы стать вашим рефералом, пользователь должен зарегистрироваться
        (первый раз зайти на сайт) по вашей реферальной ссылке:
      </p>
      <div class="p-b-20">
        <div>
          <h4 class="p-l-20 p-r-20">
            <input type="text" onClick="this.select();" class="form-control" v-bind:value="data.ref_link" />
          </h4>
        </div>
      </div>


      <p>
        Вы так же можете пригласить пользователей через бот в telegram . Эту ссылку можно отправлять и публиковать
        ВКонтакте:
      </p>
      <div class="p-b-20">
        <div>
          <h4 class="p-l-20 p-r-20">
            <input type="text" onClick="this.select();" class="form-control" v-bind:value="data.bot_link" />
          </h4>
        </div>
      </div>



      <h3>Активные реальные рефералы:</h3>
      <p>В таблице показаны 10 ваших активных рефералов.</p>

      <table class="table table-hover">
        <thead>
          <tr>
            <th>
              <h4>Реферал</h4>
            </th>
            <th>
              <h4>Заработал</h4>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ref in data.refs" v-bind:key="ref.id">
            <td>
              <div class="pull-left p-4">
                <img :src="ref.photo" alt="" width="50" height="50" />
              </div>
              <div class="pull-left p-4">
                <b>{{ ref.id }}) {{ ref.name }}</b><br />
                {{ ref.created_at }}
              </div>
            </td>
            <td>
              <b>Всего:</b> {{ ref.balance_earn }}<br />
              <b>Сегодня:</b> {{ ref.balance_day }}
            </td>
          </tr>
          <tr v-if="!data.refs">
            <td colspan="3" align="center">У вас нет рефералов</td>
          </tr>
        </tbody>
      </table>

      <h4>Всего активных рефералов: {{ data.ref_count }}</h4>
      <h4>Всего заработано на рефералах лайков: {{ data.balance_ref }}</h4>
    </div>

    <div v-else class="text-center bg-silver m-20 p-20">
      <h4>Загружаем....</h4>
    </div>
  </panel>
</template>

<script>
import HTTP from "../http";
import { show_error } from "../message";

export default {
  data() {
    return {
      loading: false,
      error: null,
      data: null,
    };
  },
  created() {
    this.fetchData();
  },
  mounted() {
    if (!localStorage.introJS_Home) {
      this.introJSStart();
    }
  },

  methods: {
    introJSStart() {
      const introJS = require("intro.js");
      introJS
        .introJs()
        .onexit(function () {
          localStorage.setItem("introJS_Ref", true);
        })
        .start();
    },

    fetchData() {
      this.error = null;
      this.loading = true;
      this.$insProgress.start();
      HTTP()
        .get("/referral")
        .then((response) => {
          this.loading = false;
          this.data = response.data;
          this.$insProgress.finish();
        })
        .catch((error) => {
          this.loading = false;
          show_error(
            this.$notify, error.response.data
          );
          this.$insProgress.finish();
        });
    },
  },
};
</script>

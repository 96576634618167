<template>
  <!-- begin #footer -->
  <div v-if="!pageOptions.pageEmpty" class="d-block d-sm-none d-md-none">
    <div class="
            bg-black
            text-black
            position-fixed
            rounded-0
            position-fixed
            mb-0
            d-md-flex
            align-items-center
         " style="left: 0; right: 0; bottom: 0; z-index: 1020; padding:7px">
      <div class="d-flex flex-row justify-content-between align-items-between ml-1 mr-1" style="width:100%">
        <a class="fa-stack fa-2x text-silver-transparent-5" href="#/home">
          <i class="far fa-square fa-stack-2x"></i>
          <i class="fas fa-home fa-stack-1x "></i>
        </a>

        <a class="fa-stack fa-2x text-silver-transparent-5" href="#/step">
          <i class="far fa-square fa-stack-2x"></i>
          <i class="fas fa-heart fa-stack-1x"></i>
        </a>

        <a class="fa-stack fa-2x text-silver-transparent-5" href="#/tasknew">
          <i class="far fa-square fa-stack-2x"></i>
          <i class="fas fa-plus fa-stack-1x "></i>
        </a>

        <a class="fa-stack fa-2x text-silver-transparent-5" href="#/shop">
          <i class="far fa-square fa-stack-2x"></i>
          <i class="fas fa-shopping-basket fa-stack-1x "></i>
        </a>

        <a class="fa-stack fa-2x text-silver-transparent-5" v-on:click="toggleMobileRightSidebar">
          <i class="far fa-square fa-stack-2x"></i>
          <i class="fas fa-user fa-stack-1x"></i>
        </a>
      </div>
    </div>
  </div>
  <!-- end #footer -->
</template>

<script>
import PageOptions from "../../config/PageOptions.vue";

export default {
  name: "Footer",
  data() {
    return {
      pageOptions: PageOptions,
    };
  },
  methods: {
    toggleMobileRightSidebar() {
      this.pageOptions.pageMobileSidebarToggled = !this.pageOptions
        .pageMobileSidebarToggled;
    },
  },
};
</script>

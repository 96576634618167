<template>
  <div>
    <panel title="Профайл">
      <table class="table table-hover">
        <tbody v-if="user.id">
          <ProfileEditableRow v-if="user.name" :record="user.name" :fieldname="`Имя`" :apiname="`name`"
            :message="`Имя изменёно`" :help="`Имя пользователя`" data-step="1" data-intro=""></ProfileEditableRow>
          <tr v-if="user.photo">
            <td>Фото</td>
            <td><img :src="user.photo" alt="" style="border-radius:5px" /></td>
          </tr>
          <tr>
            <td>id</td>
            <td>{{ user.id }}</td>
          </tr>
          <!--<ProfileEditableRow :record="user.email" :fieldname="`E-mail`" :apiname="`email`" :message="`E-mail изменён`"
            :help="`E-mail нужен для авторизации на сайте`" data-step="1" data-intro=""></ProfileEditableRow>-->
          <tr>
            <td>E-mail</td>
            <td>{{ user.email }}</td>
          </tr>
          <!--<ProfileEditableRow :record="user.password" :fieldname="`Пароль`" :apiname="`password`"
            :message="`Пароль изменён`" :help="`Пароль нужен для авторизации на сайте.`" data-step="2" data-intro="">
          </ProfileEditableRow>-->
          <tr>
            <td>Пароль</td>
            <td>*********</td>
          </tr>
          <tr>
            <td>Баланс</td>
            <td>{{ user.balance }}</td>
          </tr>
          <!--<tr>
            <td>Зарегистрирован</td>
            <td>{{ user.created_at }} ({{ user.created }})</td>
          </tr>-->

          <tr>
            <td colspan="2" class="text-center"><b>Привязанные аккаунты:</b></td>
          </tr>
          <tr v-if="user.uid != 0">
            <td>VKontakte</td>
            <td>
              <a target="_blank" :href="'https://vk.com/id' + user.uid">id{{ user.uid }}</a>
              <div class="pull-right">
                <a class="btn btn-xs btn-white" @click.prevent="deleteProfile('vk')">
                  <i class="fa fa-times"></i>
                </a>
              </div>
            </td>
          </tr>


          <tr v-if="user.uid == 0">
            <td colspan="2" align="center">
              <h4>Привязать дополнительный аккаунт:</h4>

              <div v-if="user.uid == 0" class="text-center mt-3">
                <div class="login-buttons">
                  <a :href="vk_link" class="mt-3 btn btn-social btn-vk btn-block btn-lg">
                    <span class="fab fa-vk"></span>
                    <span class="ml-3">Привязать аккаунт ВКонтакте</span>
                  </a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </panel>
  </div>
</template>

<script>
import HTTP from "../http";
import { show_message, show_error } from "../message";

export default {
  data() {
    return {
      error: null,
      loading: false,
      user: {},
      vk_link: null
    };
  },
  created() {
    this.fetchData();
    this.vk_link = process.env.VUE_APP_LOGINEZ + encodeURIComponent(process.env.VUE_APP_LOGIN_URL + '#/profile');


    let uri = window.location.href.split("=");
    let token = 0;

    if (uri[1]) {
      console.log('uri', uri)
      token = uri[1]
      HTTP()
        .post("/auth/add_network", {
          token,
        })
        .then(() => {
          this.loading = false;
          show_message(this.$notify, "Аккаунт привязан.");
          this.fetchData();
        })
        .catch((error) => {
          this.loading = false;
          show_error(
            this.$notify, error.response.data
          );
        });
    }
    console.log('token=', token)
  },

  methods: {
    deleteProfile(network) {
      this.error = null;
      this.loading = true;
      HTTP()
        .put("/user/" + localStorage.id, {
          network: network,
        })
        .then(() => {
          this.loading = false;
          show_message(this.$notify, "Аккаунт отвязан.");
          this.fetchData();
        })
        .catch((error) => {
          this.loading = false;
          show_error(
            this.$notify, error.response.data
          );
        });
    },

    fetchData() {
      this.error = null;
      this.loading = true;
      HTTP()
        .get("/user/" + localStorage.id)
        .then((response) => {
          this.loading = false;
          this.user = response.data;
        })
        .catch((error) => {
          this.loading = false;
          show_error(
            this.$notify, error.response.data
          );
        });
    },
  },
};
</script>

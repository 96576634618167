<template>
   <span>
      <div v-if="type == 'vk_like'">
         <i class="fab fa-lg fa-fw  fa-vk"></i><i class="fas fa-lg fa-fw fa-heart"></i>
      </div>
      <div v-else-if="type == 'vk_repost'">
         <i class="fab fa-lg fa-fw   fa-vk"></i><i class="fas fa-lg fa-fw fa-share-square"></i>
      </div>

      <div v-else-if="type == 'vk_cmnt'">
         <i class="fab fa-lg fa-fw   fa-vk"></i><i class="fas fa-lg fa-fw fa-comment-alt"></i>
      </div>

      <div v-else-if="type == 'vk_frnd'">
         <i class="fab fa-lg fa-fw  fa-vk"></i><i class="fas fa-lg fa-fw fa-user-plus"></i>
      </div>

      <div v-else-if="type == 'vk_group'">
         <i class="fab fa-lg fa-fw  fa-vk"></i><i class="fas fa-lg fa-fw fa-users"></i>
      </div>

      <div v-else-if="type == 'vk_poll'">
         <i class="fab fa-lg fa-fw  fa-vk"></i><i class="fas fa-lg fa-fw fa-list"></i>
      </div>

      <div v-else-if="type == 'vk_token'">
         <i class="fab fa-lg fa-fw  fa-vk"></i><i class="fas fa-lg fa-fw fa-list"></i>
      </div>

      <div v-else-if="type == 'in_like'">
         <i class="fab fa-lg fa-fw  fa-instagram"></i><i class="fas fa-lg fa-fw  fa-heart"></i>
      </div>

      <div v-else-if="type == 'in_user'">
         <i class="fab fa-lg fa-fw  fa-instagram"></i><i class="fas fa-lg fa-fw  fa-user-plus"></i>
      </div>

      <div v-else-if="type == 'in_comnt'">
         <i class="fab fa-lg fa-fw  fa-instagram"></i><i class="fas fa-lg fa-fw  fa-comment-alt"></i>
      </div>

      <div v-else-if="type == 'yt_like'">
         <i class="fab fa-lg fa-fw  fa-youtube"></i><i class="fas fa-lg fa-fw fa-thumbs-up"></i>
      </div>
      <div v-else-if="type == 'yt_dlke'">
         <i class="fab fa-lg fa-fw   fa-youtube"></i><i class="fas fa-lg fa-fw fa-thumbs-down"></i>
      </div>

      <div v-else-if="type == 'yt_favr'">
         <i class="fab fa-lg fa-fw   fa-youtube"></i><i class="fas fa-lg fa-fw fa-asterisk"></i>
      </div>

      <div v-else-if="type == 'yt_user'">
         <i class="fab fa-lg fa-fw   fa-youtube"></i><i class="fas fa-lg fa-fw fa-user-plus"></i>
      </div>

      <div v-else-if="type == 'yt_cmnt'">
         <i class="fab fa-lg fa-fw  fa-youtube"></i><i class="fas fa-lg fa-fw fa-comment-alt"></i>
      </div>
      <div v-else-if="type == 'vk_app'">
         <i class="fab fa-lg fa-fw  fa-vk"></i><i class="fas fa-lg fa-fw me-10px fa-stop"></i>
      </div>
      <div v-else>
         <i class="fas fa-lg fa-fw  fa-exclamation-circle"></i>{{ type }}<i
            class="fas fa-lg fa-fw fa-exclamation-circle"></i>
      </div>
   </span>
</template>

<script>
export default {
   name: "TaskIcon",
   props: ["type"]
};
</script>

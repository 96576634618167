<script>
const sidebarMenu = [
  {
    path: "/home",
    icon: "fas fa-desktop ",
    title: "Моя страница",
  },
  {
    path: "/step",
    icon: "far fa-heart",
    title: "Заработать лайки",
  },
  {
    path: "/referal",
    icon: "fas fa-user-plus ",
    title: "Рефералы",
  },
  {
    path: "/profile",
    icon: "fas fa-cogs ",
    title: "Настройки",
  },
  {
    path: "/support",
    icon: "fas fa-headset ",
    title: "Тех. поддержка",
  },
  {
    path: "/logout",
    icon: "fas fa-sign-out-alt ",
    title: "Выйти",
  },
];

export default sidebarMenu;
</script>

<template>
  <tr>
    <td @click="showmessage(help)">
      Ссылка на:
      <b class="pull-right pt-1">
        <div class="btn btn-xs btn-white">
          <i class="fas fa-info-circle"></i>
        </div>
      </b>
    </td>
    <td @click="goLink(link.url)">
      <input class="form-control" type="text" v-model="link.url" @input="changeRecord" :disabled="!isnewtask" />
      <div v-if="link.result" v-html="link.result" class="alert alert-success fade show m-t-10">

      </div>
    </td>
  </tr>
</template>

<script>
import { show_message } from "../message";

export default {
  name: "TaskLinkRow",
  props: [
    "isnewtask",
    "id",
    "record",
    "apiname",
    "fieldname",
    "message",
    "help",
  ],
  data() {
    return {
      link: {
        url: "",
        result: "",
      },
    };
  },

  mounted() {
    if (!this.isnewtask) {
      this.link.url = this.record;
    }
  },
  methods: {
    showmessage(message) {
      show_message(this.$notify, message);
    },
    goLink(link) {
      if (!this.isnewtask) {
        window.open(link);
      }
    },
    changeRecord() {
      if (localStorage.edit_task != null) {
        let task = JSON.parse(localStorage.edit_task);
        task[this.apiname] = this.link.url;
        localStorage.edit_task = JSON.stringify(task);
      } else {
        let task = { [this.apiname]: this.link.url };
        localStorage.edit_task = JSON.stringify(task);
      }

      if (this.link.url.match(/wall-\d+_\d+/i)) {
        this.link.net = "vk";
        this.link.type = "subs";
        this.link.isliked = true;
        this.link.result = "Пост на стене группы ВКонтакте";
      } else if (this.link.url.match(/wall\d+_\d+/i)) {
        this.link.net = "vk";
        this.link.type = "subs";
        this.link.isliked = true;
        this.link.result = "Пост на стене страницы ВКонтакте";
      } else if (this.link.url.match(/photo[0-9-]+_\d+/i)) {
        this.link.net = "vk";
        this.link.type = "subs";
        this.link.isliked = true;
        this.link.result = "Фотография  ВКонтакте";
      } else if (this.link.url.match(/video[0-9-]+_\d+/i)) {
        this.link.net = "vk";
        this.link.type = "subs";
        this.link.isliked = true;
        this.link.result = "Видео  ВКонтакте";
      } else if (this.link.url.match(/topic[0-9-]+_\d+/i)) {
        this.link.net = "vk";
        this.link.type = "subs";
        this.link.isliked = true;
        this.link.result = "Обсуждение Вконтакте";
      } else if (this.link.url.match(/id\d+/i)) {
        this.link.net = "vk";
        this.link.type = "subs";
        this.link.isliked = false;
        this.link.result = "Личная страница  ВКонтакте";
      } else if (this.link.url.match(/club\d+/i)) {
        this.link.net = "vk";
        this.link.type = "subs";
        this.link.isliked = false;
        this.link.result = "Группа  ВКонтакте";
      } else if (this.link.url.match(/vk.com/i)) {
        this.link.net = "vk";
        this.link.type = "subs";
        this.link.isliked = false;
        this.link.result = "Страница или группа ВКонтакте";
      } else {
        this.link.net = "vk";
        this.link.type = "subs";
        this.link.isliked = true;
        this.link.result = "Ссылка не определена. Здесь можно добавлять только задания для ВКонтакте. Если Вам нужно добавить ссылку на другой сайт (instagram, youtube или  любой другой сервис или сайт ) попробуйте   <a href='http://fastsmm.ru' target='_blank'>fastsmm.ru</a>";
      }
      /*} else if (this.link.url.match(/instagram.com\/p\//i)) {
        this.link.net = "in";
        this.link.type = "subs";
        this.link.isliked = true;
        this.link.result = "Страница Instagramm";
      } else if (this.link.url.match(/instagram.com/i)) {
        this.link.net = "in";
        this.link.type = "subs";
        this.link.isliked = false;
        this.link.result =
          "Аккаунт Instagramm. " +
          "Внимание! Аккаунт должен быть не приватным. " +
          "Приватные аккаунты блокируются системой.";
      } else if (
        this.link.url.match(/youtube.com\/watch/i) ||
        this.link.url.match(/youtu.be\//i)
      ) {
        this.link.net = "yt";
        this.link.type = "subs";
        this.link.isliked = true;
        this.link.result = "Youtube: ссылка на видео";
      } else if (
        this.link.url.match(/youtube.com\/channel/i) ||
        this.link.url.match(/youtube.com\/user/i)
      ) {
        this.link.net = "yt";
        this.link.type = "subs";
        this.link.isliked = false;
        this.link.result = "Youtube: ссылка на канал";
      } else {
        this.link.net = "yt";
        this.link.type = "subs";
        this.link.isliked = true;
        this.link.result = "Ссылка не определена";
      }*/

      window.eventBus.$emit("link_chaned", this.link);
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.pageOptions.pageEmpty)?_c('div',{staticClass:"fade page-sidebar-fixed page-header-fixed show page-container",class:{
      'page-sidebar-minified': _vm.pageOptions.pageSidebarMinified,
      'page-content-full-height': _vm.pageOptions.pageContentFullHeight,
      'page-without-sidebar': _vm.pageOptions.pageWithoutSidebar,
      'page-with-right-sidebar': _vm.pageOptions.pageWithRightSidebar,
      'page-with-two-sidebar': _vm.pageOptions.pageWithTwoSidebar,
      'page-with-wide-sidebar': _vm.pageOptions.pageWithWideSidebar,
      'page-with-light-sidebar': _vm.pageOptions.pageWithLightSidebar,
      'page-with-top-menu': _vm.pageOptions.pageWithTopMenu,
      'page-sidebar-toggled': _vm.pageOptions.pageMobileSidebarToggled,
      'page-right-sidebar-toggled':
        _vm.pageOptions.pageMobileRightSidebarToggled ||
        _vm.pageOptions.pageRightSidebarToggled,
      'has-scroll': _vm.pageOptions.pageBodyScrollTop,
    }},[_c('Header'),(_vm.pageOptions.pageWithTopMenu)?_c('TopMenu'):_vm._e(),(!_vm.pageOptions.pageWithoutSidebar)?_c('Sidebar'):_vm._e(),(_vm.pageOptions.pageWithTwoSidebar)?_c('SidebarRight'):_vm._e(),_c('div',{staticClass:"content",class:{
      'content-full-width': _vm.pageOptions.pageContentFullWidth,
      'content-inverse-mode': _vm.pageOptions.pageContentInverseMode,
    },staticStyle:{"margin-bottom":"90px"},attrs:{"id":"content"}},[_c('router-view'),_c('vue-ins-progress-bar')],1)],1):_c('div',[_c('router-view'),_c('vue-ins-progress-bar')],1),_c('div',{staticClass:"d-block d-sm-none d-md-none"},[_c('notifications',{attrs:{"group":"bottom-right","position":"top center","speed":1000}})],1),_c('div',{staticClass:"d-none d-sm-block"},[_c('notifications',{attrs:{"group":"bottom-right","position":"bottom right","speed":1000}})],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }

<template>
    <div>
        <panel title="Техническая поддержка">
            <div>
                <!-- BEGIN -->

                <!--<h3>Покупка лайков:</h3>
                <h5>Как купить лайки?</h5>
                <p>Слева вверху рядом с Вашим балансом есть кнопка "купить", нажмите на нее.
                </p>
                <h5>Как купить лайки из Украины, Белорусии?</h5>
                <p>Лучше всего через <b>Visa/MasterCard МИнБАНК RUR</b>
                </p>
                <h5>Можно ли купить лайки за голоса вконтакте?</h5>
                <p>Нет, мы не продаем лайки за голоса.
                </p> -->
                <h3>Если что-то не работает:</h3>
                <h5>Не начисляются лайки за задания:</h5>
                <p>Вам нужно нажать кнопку проверки задания после его выполнения.
                    Кнопка проверки - это синяя кнопка напротив ссылки на задание.
                    Лайки будут зачислены сразу после проверки.
                    Убедитесь, что ВКонтакте вы авторизованы так же, как и на нашем сервисе </p>
                <p></p>
                <h5>Не накручивается группа, не добавляются друзья, не делаются репосты...</h5>
                <p>Попробуйте поднять цену заказа. Перейдите на вкладку заданий и посмотрите
                    какая максимальная цена стоит у конкурентов.
                </p>
                <p>Что бы отредактировать задание (изменить цену, добавить подписчиков и т.п.)
                    перейдите на вкладку 'Моя страница' и нажмите кнопку редактирования справа от заказа
                </p>
                <!--<h3>Рефералы:</h3>
                <h5>Пользователь перешел по моей реферальной ссылке, но его нет у меня в таблице рефералов:</h5>
                <p>Значит он уже заходил на сайт раньше
                </p>
                <h5>Не начисляются лайки за реферала:</h5>
                <p> Лайки начисляются не сразу, а раз в 24 часа.
                </p>-->
                <h3>Другие вопросы:</h3>
                <h5>Как отредактировать заказ?</h5>
                <p>На вкладке Моя страница есть история всех ваших заказов. Вам нужно нажать кнопку редактирования
                    напротив нужного заказа (синяя). Там можно изменить цену чтобы увеличить скорость выполнения
                    или добавить лайков на заказ.
                </p>
                <h5>Если удалить заказ, лайки вернутся?</h5>
                <p>Да, при удалении или редактировании заказа лайки возвращаются на Ваш баланс. Если что-то
                    не пришло на всякий случае еще раз обновите страничку. (Нажмите F5).
                </p>
                <h5>Есть еще вопросы?</h5>
                <p>Пишите в <a target="_blank" href="https://t.me/viktor1816">техподдержку</a>.  Обязательно
                    укажите ваш <b>id-{{ id }}</b>.</p>
                <!-- END -->
            </div>

        </panel>
    </div>
</template>


<script>
export default {
    data() {
        return {
            id: 0
        };
    },
    created() {
        this.id = localStorage.id
    },

};
</script>

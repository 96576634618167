<template>
  <div v-if="!loading">
    <a v-if="network == 'instagram'" :href="link" class="btn btn-lg width-250 btn-social btn-instagram  text-white"
      style="background-color: #E1306C;">
      <span class="fab fa-instagram"></span>
      <span class="ml-3">Привязать Instagram</span>
    </a>
    <a v-if="network == 'vkontakte'" :href="link" class="btn btn-lg width-250 btn-social btn-vk  text-white">
      <span class="fab fa-vk"></span>
      <span class="ml-3">Привязать Vkontakte</span>
    </a>
    <a v-if="network == 'youtube'">В разаработке </a>
  </div>
</template>

<script>
import HTTP from "../http";
import { show_message, show_error } from "../message";

export default {
  name: "SocAddButton",
  props: ["network", "route"],
  data() {
    return {
      link:
        "https://loginez.com/auth/?redirect_uri=" +
        encodeURIComponent(process.env.VUE_APP_LOGIN_URL) +
        "&method=get&route=" +
        this.route +
        "&network=" +
        this.network,
      loading: false,
    };
  },
  created() {
    let getVars = {};
    let uri = window.location.href.split("?");

    if (uri.length == 2) {
      let vars = uri[1].split("&");

      let tmp = "";
      vars.forEach(function (v) {
        tmp = v.split("=");
        if (tmp.length == 2) getVars[tmp[0]] = tmp[1];
      });
      console.log(getVars);

      if (getVars.token && !localStorage.getItem("profile_add")) {
        localStorage.setItem("profile_add", "loading");
        //this.loading = true;
        HTTP()
          .post("profile/add", {
            token: getVars.token,
          })
          .then(() => {
            show_message(this.$notify, "Аккаунт привязан.");
            this.fetchData();
          })
          .catch((error) => {
            console.log(error);
            show_error(
              this.$notify, error.response.data
            );
          })
          .finally(() => {
            //this.loading = false;
            localStorage.setItem("profile_add", "");
            location.href = "/#/" + this.route;
          });
      }
    }
  },

  methods: {},
};
</script>

import Vue from "vue";
import VueX from "vuex";
import routes from "./config/PageRoutes";
import { store } from "./store/store";

// plugins
import VueRouter from "vue-router";
import VueBootstrap from "bootstrap-vue";
import VueInsProgressBar from "vue-ins-progress-bar";
import VueSweetalert2 from "vue-sweetalert2";
import VueNotification from "vue-notification";
import VuePlugins from "./plugins/";
import VueGoodTablePlugin from "vue-good-table";

// plugins css
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-social/bootstrap-social.css";
import "intro.js/minified/introjs.min.css";

// color admin css
import "./assets/css/apple/style.css";
import "./assets/css/apple/style-responsive.css";
import "./assets/css/apple/theme/default.css";
import "./assets/css/style.css";
import "./assets/css/vue-good-table.css";

import App from "./App.vue";

Vue.use(VueX);
Vue.use(VueRouter);
Vue.use(VueBootstrap);
Vue.use(VueSweetalert2);
Vue.use(VueNotification);
Vue.use(VuePlugins);
Vue.use(VueGoodTablePlugin);

Vue.use(VueInsProgressBar, {
  position: "fixed",
  show: true,
  height: "10px",
});




// import the styles

const router = new VueRouter({
  routes,
});

import PageOptions from "./config/PageOptions.vue";

router.beforeEach((to, from, next) => {
  
   if (to.name == "EmailConfirm"){
     PageOptions.pageEmpty = true;
     next();
   }
   else if (to.name == "ResetPassword"){
     PageOptions.pageEmpty = true;
     next();
   }
   else if (to.name == "ForgotPassword"){
     PageOptions.pageEmpty = true;
     next();
   }

  else if (!store.getters.loggedIn) {
    PageOptions.pageEmpty = true;
    if (to.name != "Login" && to.name != "Register" ) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else {
    if (to.name != "Login" && to.name != "Register" ) {
      next();
    } else {
      next({ name: "step" });
    }
  }
  /*
  if (to.name == "Login") {
    if (store.getters.loggedIn) {
      //next({ name: "step" });
      router.push({ path: "step" });
    } else {
      PageOptions.pageEmpty = true;
    }
  }

  if (to.name == "Register") {
    if (store.getters.loggedIn) {
      //next({ name: "step" });
      router.push({ path: "step" });
    } else {
      PageOptions.pageEmpty = true;
    }
  }

  if (
    to.name !== "Login" &&
    to.name !== "Register" &&
    !store.getters.loggedIn
  ) {
    PageOptions.pageEmpty = true;
    //next({ name: "Login" });
    router.push({ path: "login" });
  } else {
    next();
  }
  */
});

//store.dispatch("serviceLogout");
/*
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
        name: "Login",
      });
    } else {
      next();
    }
  }

  if (to.matched.some((record) => !record.meta.requiresAuth)) {
    if (store.getters.loggedIn) {
      next({
        name: "step",
      });
    } else {
      next();
    }
  }
});
*/
window.eventBus = new Vue();

/*
Vue.config.productionTip = false
Vue.config.devtools = false;
Vue.config.debug = false;
Vue.config.silent = true;
*/

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
